<!-- <app-header></app-header> -->
<div class="container-fluid ">
  <div class="row" id="top_view">
    <div class="col s12">
      <h1>Employee Profile</h1>
    </div>
  </div>
  <div class="row employee-profile">
    <div class="col s12" style="padding: 0">
      <div class="background-img">
        <img src="../../../assets/images/199fd29184c6cff24e3445f849af463e.png" alt="" />
      </div>
    </div>
  </div>
  <div class="row profile-row">
    <div class="col xl6  s12" style="display: flex;align-items: start">
      <div class="avtar">
        <user-profile [user]="userDetails" [isName]="false" [userImageClass]="userImageClass"></user-profile>
      </div>
      <!-- </div> -->
      <div class="avtar-name-container">
        <h2>
          <span class="user-name">{{trimName( userDetails && userDetails.name )}}</span>
          <div class="badge" *ngIf="status != 'not-using' && status != 'online'">
            Last active at
            <span>
              {{ lastActiveTimeTz }}
            </span>
          </div>
          <div *ngIf="!status || status === 'not-using'">
            <span class="away-badge">Invited</span>
          </div>
          <div *ngIf="status == 'online'">
            <span class="online-badge">Online</span>
          </div>
          <div class="badge logout-reason-badge" *ngIf="logoutReason && status != 'online'">
            Logged out because:
            <span>
              {{ logoutReason }}
            </span>
          </div>
        </h2>
      </div>
    </div>
    <div class="col xl6  s12 profile-row-btn-container">
      <button mat-raised-button *ngIf="status != 'online' && status != 'not-using'"
        class="sc-request screenrecorder-req sc-request-disable" (click)="
          status && status == 'online' ? openScreencastActionModel() : ''
        ">
        <span class="material-symbols-outlined"> cast </span>Screencast request
      </button>
      <button mat-raised-button *ngIf="status == 'online'" class="sc-request screenrecorder-req" (click)="
          status && status == 'online' ? openScreencastActionModel() : ''
        ">
        <span class="material-symbols-outlined"> cast </span>Screencast Request
      </button>
      <button mat-raised-button *ngIf="status != 'online' && status != 'not-using'"
        class="sc-request sc-request-disable" (click)="status && status == 'online' ? screenshotRequest() : ''">
        <span class="material-symbols-outlined"> photo_camera </span>Screenshot
        Request
      </button>
      <button mat-raised-button *ngIf="status == 'online'" class="sc-request"
        (click)="status && status == 'online' ? screenshotRequest() : ''">
        <span class="material-symbols-outlined"> photo_camera </span>Screenshot
        Request
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col s12 m12 l6 xl5  statics-container">
      <mat-card class="workin-card">

        <div class="barHeader">
          <div class="">
            <mat-card-title class="chartTitle">Statistics</mat-card-title>
          </div>
          <div class="displayWeek">
            <i class="material-icons" (click)="WeekPrevNextAction(true,false)"
              style="cursor: pointer">keyboard_arrow_left</i>
            <p class="statics-current-week">{{currentWeekDisplay}}</p>
            <i class="material-icons" (click)="WeekPrevNextAction(false,false)"
              style="cursor: pointer">keyboard_arrow_right</i>
          </div>
          <div class="col s12 m4 barLabel">
            <div style="display: inline-flex;align-items: center;">
              <div
                [ngStyle]="{'width': '12px','background-color': '#6569D5','height': '12px','text-align': 'end','border-radius':'50%'}">
              </div>
              <div class="hours-name">
                Working Hours
              </div>
            </div>
            <div style="display: inline-flex;align-items: center;">
              <div
                [ngStyle]="{'width': '12px','background-color': '#C5C7EB','height': '12px','text-align': 'end','border-radius':'50%'}">
              </div>
              <div class="hours-name">
                Idle Hours
              </div>
            </div>
          </div>

        </div>


        <mat-card-content>
          <div *ngIf="_isStackbarLoading">
            <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
              [theme]="{ 'border-radius': '2', height: '400px', margin: '5px' }"></ngx-skeleton-loader>
          </div>
          <div>
            <app-stacked-chart *ngIf="!_isStackbarLoading" [totalWorkingArray]="totalWorkingHours" [data]="sessionData"
              [weekArr]="currentWeekArray" [labels]="barChartLabels" [type]="'bar'"
              [sessionData]="barData"></app-stacked-chart>

          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col s12 m12 l6 xl7">
      <mat-card class="workin-card">
        <div class="donutHeader">
          <div class="row most-used-container">
            <div class="col s12 xl6 most-used-contain">
              <div class="chartTitle">Most Used Application</div>
            </div>
            <div class="col s12 xl6 most-used-daterange-contain">

              <mat-form-field class="date-range-picker-form-field">

                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate [value]="startDate" placeholder="Start date"
                    (dateChange)="onStartDateChange($event)" />
                  <input matEndDate [value]="endDate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
                  <input matStartDate placeholder="Start date" />
                  <input matEndDate placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>

            </div>

          </div>
        </div>
        <mat-card-content class="most-used-card-content"
          style="display: flex; align-items: center;justify-content: center;">

          <div class="row">
            <div class="col xl7 s12 m6 l12">
              <div style="max-width: 320px;max-height: 320px;margin-left: auto;margin-right: auto;">
                <application-usage></application-usage>
              </div>
            </div>
            <div class="col xl5 s12 m6 l12 progressbar-contain">
              <progressbar></progressbar>
            </div>

          </div>

        </mat-card-content>
      </mat-card>
    </div>

  </div>
  <div class="row timeline">
    <div class="col s12">
      <mat-card class="employyee-work-timeline">
        <div class="barHeader">
          <div class="" style="margin-right: 1rem;flex-grow: 1;">
            <mat-card-title class="chartTitle">Employee Work Timeline</mat-card-title>
          </div>
          <div class="displayWeek" style="align-items: center;display: flex;">
            <i class="material-icons" (click)="WeekPrevNextAction(true,true)"
              style="cursor: pointer">keyboard_arrow_left</i>{{stackcurrentWeekDisplay}}
            <i class="material-icons" (click)="WeekPrevNextAction(false,true)"
              style="cursor: pointer">keyboard_arrow_right</i>
          </div>

        </div>
        <mat-card-content>
          <div *ngIf="!stackedChartData">
            <ngx-skeleton-loader style="margin: 20px 0px; width: 100%;"
              [theme]="{'border-radius' : '2' , 'height' : '400px', 'margin' : '5px'}"></ngx-skeleton-loader>
          </div>
          <div>
            <app-stacked-chart *ngIf="stackedChartData && stackedChartData.length" [type]="'horizontal'"
              [data]="stackedChartData" [minValue]="x_min" [maxValue]="x_max" [labels]="stackedChartlabel"
              [tempDataTooltip]="tempDataTooltip" [newSessionData]="newSessionTimeline"></app-stacked-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col s12" style="margin-top: 28px; margin-bottom: 20px">

      <app-screen-shot-viwer [userDetails]="userDetails" [user]="userId" [status]="status"
        (childEvent)="addName($event)" [startDate]="sendingStartDate"
        [endDate]="sendingEndDate"></app-screen-shot-viwer>


    </div>
  </div>
</div>
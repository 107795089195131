import { Component, HostListener, OnInit, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
//import { Socket } from "ngx-socket-io";
import { LoginService } from "../../Services/login.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { MatDialog } from "@angular/material/dialog";
import { ScreencastActionModelComponent } from "src/app/screencast/screencast-action-model/screencast-action-model.component";
import { ProjectService } from "src/app/Services/project.service";
import { OverlayRef, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Observable, Subscription } from "rxjs";
import { SharedService } from "src/app/Services/sharedService";
import { map } from "rxjs/operators";
import { DatePipe, ViewportScroller } from "@angular/common";
import { LogsDisplayModalComponent } from "../logs-display-modal/logs-display-modal.component";
import * as moment from "moment";
declare var $;
import { trigger, transition, query, style, animate, group } from '@angular/animations';
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { CustomTimeAgoPipe } from '../../time-ago.pipe';
import { MatSnackBar } from "@angular/material/snack-bar";

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.css"],
  providers: [DatePipe],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
})
export class TabsComponent implements OnInit {
  userDataSubscription: Subscription;
  stackedArr: any = [];
  isScreenCast = false;
  pieChartAllData: any;
  endDate: any = new Date();
  startDate;
  ismodelOpen = false;
  userId: any;
  userDetails: any;
  screenShareImage =
    "https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80";
  loading = false;
  screenCastImage: string;
  selectedTab = "Application Usages";
  screenCastRequestStatus: boolean = false;
  receivedData: string;
  private dbPath = localStorage.getItem('dbPath');
  usersRef: AngularFireList<User> = null;
  stackedChartData: any;
  selectedTabIndex;
  status: string;
  donutChartData: any;
  totalWorkingHours: any;
  lastActiveTime: any;
  lastActiveTimeTz: any = null;
  sendingStartDate: any = null;
  sendingEndDate: any = null;
  x_min: number;
  stackedChartlabel: any[];
  sessionData: any;
  barChartLabels: any;
  pieChartData: any[];
  pieChartLabel: any[];
  pieChartDisplayData: any[];
  tempDataTooltip: any = []
  newSessionTimeline: any = [];
  barData = [];
  x_max: number;
  currentDate: any = moment();
  _isAppUsageLoading = false
  _isStackbarLoading: boolean = false;
  currentDateOfWeek = new Date();
  currentDateOfStart;
  currentDateofEnd;
  currentWeekDisplay;
  currentWeekArray = [];

  stackcurrentDateOfWeek = new Date();
  stackcurrentDateOfStart;
  stackcurrentDateofEnd;
  stackcurrentWeekDisplay;
  stackcurrentWeekArray = [];
  appUsageColor = ['#ff6f61', // Red
    '#ffcc5c', // Gold
    '#ffa07a', // Light Salmon
    '#ffeb99', // Pale Goldenrod
    '#98fb98', // Pale Green
    '#00ced1', // Dark Turquoise
    '#87ceeb', // Sky Blue
    '#6495ed', // Cornflower Blue
    '#9370db', // Medium Purple
    '#c71585', // Medium Violet Red
  ]
  workingHours: any;
  userImageClass = 'profile-avatar-img';
  isBtnDisabled = '';
  logoutReason: string;

  constructor(
    private _route: ActivatedRoute,
    private _loginService: LoginService,
    //private socket: Socket,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private _projectService: ProjectService,
    private _sharedService: SharedService,
    private _router: Router,
    private datePipe: DatePipe,
    private _messagingService: MessagingServiceService,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private _snackBar: MatSnackBar,
    private scroller: ViewportScroller,
  ) {
    if (document.getElementById('TooltipId')) {
      var existingTooltip = document.getElementById('TooltipId');
      existingTooltip.parentNode.removeChild(existingTooltip);
    }
    // this.scroller.scrollToAnchor('top_view');
    _messagingService.showNotification();
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    );
    document.getElementById("pieChartContainer")
      ? document.getElementById("pieChartContainer").remove()
      : "";
    document.getElementById("chartContainer")
      ? document.getElementById("chartContainer").remove()
      : "";

    document.getElementById("donutChartNewContainer")
      ? document.getElementById("donutChartNewContainer").remove()
      : "";

    document.getElementById("donutChartNewContainer")
      ? document.getElementById("donutChartNewContainer").remove()
      : "";


    // console.log(this._sharedService.isEdit, "shared");

    this._projectService.screencastRequest.subscribe((value) => {
      this.dialog.closeAll();
      this.ismodelOpen = value;
      if (value == false) {
        this.screenCastRequestStatus = false;
        this._loginService.requestScreenCastOff({ user: this.userId });
        this.db.database
          .ref(`${this.dbPath}`)
          .child(`${this.userId}`)
          .update({ scRequestStop: true });
        this.screenCastImage = null;
        this.isScreenCast = false;
      }
    });

    this.selectedTabIndex = 0;
    this._route.params.subscribe((params) => {
      // console.log("params in tabs ==>", params);
      // this.userId = params.userId;
      // this.getstatus();
      if (!this.userId) {
        this.userId = params.userId
        this.getstatus();
      } else {
        if (this.userId != params.userId) {
          this.startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          );
          this.userId = params.userId
          this.getstatus();
          this.ngOnInit();

          this.getPieChartData(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"));
          // this.getStackedChartData(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"));
          this.getWeek(0);
          this.updateWeekText(true)
          this.updateWeekText(false)

        } else {
          this.userId = params.userId
          this.getstatus();
        }
      }

      // this.ngAfterViewInit();
    });
    // this.socket.removeAllListeners();

    // this.getUserDetails();

    $(document).ready(function () {
      // console.log($(".tooltipped"));

      $(".tooltipped").tooltip();
    });

    if (this.lastActiveTimeTz != null) {
      this.getUserCurrentStatus(this.lastActiveTimeTz, true);
    } else {
      this.getUserCurrentStatus(this.lastActiveTime, false);
    }

  }

  ngAfterViewInit() {
    this.getPieChartData(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"));
    // this.getStackedChartData(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"));
    this.getWeek(0);
    this.updateWeekText(true)
    this.updateWeekText(false)
    // document.getElementById("donutChartNewContainer")
    // ? document.getElementById("donutChartNewContainer").remove()
    // : "";
    // this.getBarChartData(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"));

  }

  updateWeekText(isstack) {
    if (isstack) {
      const startOfWeek = new Date(this.stackcurrentDateOfWeek);
      // console.log("startOfWeek", startOfWeek);
      startOfWeek.setDate((this.stackcurrentDateOfWeek.getDate() - this.stackcurrentDateOfWeek.getDay()));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const startDateString = startOfWeek.getDate() + ' ' + this.getMonthName(startOfWeek) + ' ' + startOfWeek.getFullYear();
      const endDateString = endOfWeek.getDate() + ' ' + this.getMonthName(endOfWeek) + ' ' + endOfWeek.getFullYear();
      this.stackcurrentWeekDisplay = startOfWeek.getDate() + ' - ' + endDateString;
      this.stackcurrentDateOfStart = startOfWeek;
      this.stackcurrentDateofEnd = endOfWeek;

      // this.getBarChartData(this.datePipe.transform(this.currentDateOfStart, "yyyy-MM-dd"), this.datePipe.transform(this.currentDateofEnd, "yyyy-MM-dd"));
      this.stackcurrentWeekArray = this.getWeekArray(startDateString, endDateString, true);
      this.getStackedChartData(this.datePipe.transform(this.stackcurrentDateOfStart, "yyyy-MM-dd"), this.datePipe.transform(this.stackcurrentDateofEnd, "yyyy-MM-dd"));

    } else {
      const startOfWeek = new Date(this.currentDateOfWeek);
      // console.log("startOfWeek", startOfWeek);
      startOfWeek.setDate((this.currentDateOfWeek.getDate() - this.currentDateOfWeek.getDay()));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const startDateString = startOfWeek.getDate() + ' ' + this.getMonthName(startOfWeek) + ' ' + startOfWeek.getFullYear();

      // console.log("startdatestring", startOfWeek);
      const endDateString = endOfWeek.getDate() + ' ' + this.getMonthName(endOfWeek) + ' ' + endOfWeek.getFullYear();
      this.currentWeekDisplay = startOfWeek.getDate() + ' - ' + endDateString;
      this.currentDateOfStart = startOfWeek;
      this.currentDateofEnd = endOfWeek;
      this.currentWeekArray = this.getWeekArray(startDateString, endDateString, false);
      this.getBarChartData(this.datePipe.transform(this.currentDateOfStart, "yyyy-MM-dd"), this.datePipe.transform(this.currentDateofEnd, "yyyy-MM-dd"));
    }
  }

  getWeekArray(startDate, endDate, isstack) {
    const weekArray = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      weekArray.push(isstack ? this.datePipe.transform(new Date(currentDate), "MMM dd, yyyy") : this.datePipe.transform(new Date(currentDate), "yyyy-MM-dd"));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weekArray;
  }

  getMonthName(date: Date): string {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[date.getMonth()];
  }
  WeekPrevNextAction(prev, isstack) {
    if (prev) {
      if (isstack) {
        this.stackcurrentDateOfWeek = this.stackcurrentDateOfStart;
        this.stackcurrentDateOfWeek.setDate(this.stackcurrentDateOfWeek.getDate() - 7);
      } else {
        this.currentDateOfWeek = this.currentDateOfStart;
        this.currentDateOfWeek.setDate(this.currentDateOfWeek.getDate() - 7);
      }
      this.updateWeekText(isstack);
    } else {
      if (isstack) {
        this.stackcurrentDateOfWeek = this.stackcurrentDateOfStart;
        this.stackcurrentDateOfWeek.setDate(this.stackcurrentDateOfWeek.getDate() + 7);
      } else {
        this.currentDateOfWeek = this.currentDateOfStart;
        this.currentDateOfWeek.setDate(this.currentDateOfWeek.getDate() + 7);
      }
      this.updateWeekText(isstack);
    }
  }
  ngOnInit() {

    this.loading = true;
    this.getUserDetails();
  }

  // Bar Chart data :

  getBarChartData(start, end) {
    this._isStackbarLoading = true;
    this.barData = [];
    this.totalWorkingHours = [];
    this._projectService.getWorkinHour(start, end, this.userId).subscribe(
      (user: any) => {
        // console.log("USER DATA ===>", user, start, end);
        // this.totalWorkingHours = user;
        let data;
        let ideal = [];
        let total = [];
        let productive = [];
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        // let barChartLabels = [];
        let floatIdealTime = [];
        let floatUsageTime = [];
        let label = [];

        // console.log(user, this.currentWeekArray, "user----->")

        this.currentWeekArray.forEach((day) => {
          const date = new Date(day);
          const dayOfWeek = date.getDay();

          const dayName = days[dayOfWeek];
          if (!user[day]) {
            let lab = [
              dayName
            ];
            ideal.push('00:00:00');
            this.totalWorkingHours.push(null)
            // let idelTime = user[day].idle;
            // let productiveTime = user[day].productive;
            floatIdealTime.push('00:00:00');
            floatUsageTime.push('00:00:00');
            total.push('00:00:00');
            // console.log("label ::", lab); // this for label of the bar chart
            label.push(lab);
          } else {
            let lab = [
              dayName
            ];

            // console.log("label ::", lab); // this for label of the bar chart
            label.push(lab);
            this.totalWorkingHours.push(user[day])
            ideal.push(user[day].idle);
            let idelTime = user[day].idle;
            let productiveTime = user[day].productive;
            this.barData.push(user[day])
            floatIdealTime.push(this.timeToFloat(idelTime));
            floatUsageTime.push(this.timeToFloat(productiveTime));
            total.push(user[day].working);
            // console.log("idealllll", floatIdealTime, floatUsageTime)
          }
        });

        this.barChartLabels = label;

        this.sessionData = [
          {
            label: "Productive Hours",
            data: floatUsageTime,
            backgroundColor: "#6569D5",
            borderColor: "#ffffff",
            borderWidth: 0,
            barThickness: 36,
            borderRadius: 10
          },
          {
            label: "Idle Hours",
            data: floatIdealTime,
            backgroundColor: "#C5C7EB",
            borderColor: "#ffffff",
            borderWidth: 0,
            barThickness: 36,
            borderRadius: 10
          }
        ];
        data = {
          idle_time: this.addTimes(ideal) ? this.addTimes(ideal) : "00:00:00",
          productive_time: this.addTimes(productive)
            ? this.addTimes(productive)
            : "00:00:00",
          usage_time: this.addTimes(total) ? this.addTimes(total) : "00:00:00",
        };
        console.warn("BarChartMethod Data : ", data);
        this.donutChartData = data;
        this._isStackbarLoading = false;
      },
      (err) => {
        console.error("Error:", err, err.status);
        if (err) {
          this._projectService.updateBarChartData(err);
        }
        if (err.status == 400) {
          this.donutChartData = err.status;
          this.sessionData = [];
          this.barData = [];
        }
        this._isStackbarLoading = false;
      }
    );
  }

  async getPieChartData(start, end) {
    // console.log("user piechart");
    this._isAppUsageLoading = true;
    this._projectService.getAppUsageCount(start, end, this.userId).subscribe(async (res) => {
      // console.log("ALL USAGE COUNT DATA", res)
      let data = [];
      let label = [];
      let display = [];
      const chartData = await this.getApplicationUsageData(res)
      // console.log("CHART DATA", chartData)
      chartData.forEach((item) => {
        if (item.appName || item.appName != "") {
          label.push(item.appName);
          data.push(item.usageTime);
          display.push(item);
        }
      })
      this.pieChartData = data;
      this.pieChartLabel = label;
      this.pieChartDisplayData = display;
      this._isAppUsageLoading = false
      if (!data.length) {
        data = undefined;
      }
      if (!label.length) {
        label = undefined;
      }
      if (!display.length) {
        display = undefined;
      }
      this._projectService.updateApplicationUsageData({ data: data, label: label, display: display })
    },
      (err) => {
        this.pieChartData = [];
        this._isAppUsageLoading = false
        this._projectService.updateApplicationUsageData({ data: undefined, label: undefined, display: undefined })
      }
    );
  }


  async getApplicationUsageData(appUsage) {
    let data = [];
    if (!appUsage) {
      return [];
    }
    for (let key in appUsage) {
      // console.log("SINGLE KEY WITH USER DATA", key)
      // label.push(key);
      data.push({ appName: key, usageTime: appUsage[key].usageTime != 0 ? appUsage[key].usageTime : '00:00:00', productive_time: appUsage[key].productive_time, idleTime: appUsage[key].idleTime });
      // display.push(user[key]);
    }

    // console.log("ALL DATA LOGS LIST", data)

    // Your original array of objects

    // Sort the original array in descending order based on usageTime
    data.sort((a, b) => {
      const timeA = this.parseTime(a.usageTime);
      const timeB = this.parseTime(b.usageTime);
      return timeB - timeA;
    });

    // Function to parse the usageTime into seconds


    // Extract the top 10 elements
    const top10Array = data.slice(0, 10);

    // Log the top 10 elements
    // console.log("THIS IS THE TOP 10 ELEMENT DATA", top10Array);
    return top10Array

  }

  parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  makeStackBarChartData(data, min, max) {
    try {
      if (data) {
        this.tempDataTooltip = [];
        this.newSessionTimeline = [];
        /** @note calculate min and max values for plotting chart. */
        this.x_min = min;
        this.x_max = max;
        if (Number(this.x_min) > Number(this.x_max)) {
          this.x_max = 23.59;
        } else {
          if (Number(this.x_max) - Number(this.x_min) < 8.0) {

            if (Number(this.x_min) + 8.0 > 23.59) {
              this.x_max = 23.59;
            } else {
              this.x_max = Number(this.x_min) + 8.0
              this.x_max = Math.ceil(this.x_max)
            }
          } else {
            this.x_max = Math.ceil(this.x_max);
          }
        }
        const newDateLogs = {};
        const dataset = new Map();
        for (const date in data) { // loop dates
          const sessions = data[date].sessions; // store sessions for current date
          const chartData = this._sharedService.gettingTheCorrectSession(sessions, 'timeline');
          this.newSessionTimeline.push(sessions)
          this.tempDataTooltip.push(chartData.sessions);
          if (sessions) {
            /** @note processing logs for one date's session. */
            let sessionLogs = [];
            if (!sessions.length) {
              newDateLogs[date] = sessionLogs;
              continue;
            }
            for (let it = 0; it < sessions.length; it++) {
              let item = sessions[it];
              let idlebool = item.idle != "00:00:00";
              let usagebool = item.usage != "00:00:00";
              let data = this._sharedService.formateTimeInDecimal(usagebool ? item.usage : item.idle);
              const itemout = moment(item.in).format('YYYY-MM-DD') === moment(item.out).format('YYYY-MM-DD') ? moment(item.out).format('HH:mm:ss') : '23:59:59';

              if (it === 0) { // @note always consider first log to be either, never both.
                sessionLogs.push({
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout, // moment(item.out).format('HH:mm:ss'),
                  label: idlebool ? 'idle' : 'usage',
                });
              }
              let prevSessionLog = { ...sessionLogs[sessionLogs.length - 1] };

              // @note for previous idle log and current idle log
              if (it > 0 && !usagebool && idlebool && prevSessionLog['label'] === 'idle' && prevSessionLog['usage'] === "00:00:00") {
                const difference = this._sharedService.getTimeDifference(prevSessionLog.out, moment(item.in).format('HH:mm:ss'))
                /** @note if the difference is more than 1 minute seperate the logs */
                if (difference > 0.02) {
                  sessionLogs.push({ // will always be idle log
                    ...item,
                    data,
                    date: moment(item.in).format('YYYY-MM-DD'),
                    in: moment(item.in).format('HH:mm:ss'),
                    out: itemout, // moment(item.out).format('HH:mm:ss'),
                    label: 'idle',
                  });
                } else {
                  prevSessionLog.out = itemout, // moment(item.out).format('HH:mm:ss');
                    prevSessionLog.data += data;
                  prevSessionLog.appName = item.appName;
                  prevSessionLog.windowName = item.windowName;
                  sessionLogs[sessionLogs.length - 1] = prevSessionLog;
                }
              }

              if (it > 0 && !usagebool && idlebool && prevSessionLog['label'] === 'usage') {
                sessionLogs.push({ // will always be idle log
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout, // moment(item.out).format('HH:mm:ss'),
                  label: 'idle',
                });
              }

              // @note for previous usage log and current usage log
              if (it > 0 && usagebool && !idlebool && prevSessionLog['label'] === 'usage' && prevSessionLog['idle'] === "00:00:00") {
                const difference = this._sharedService.getTimeDifference(prevSessionLog.out, moment(item.in).format('HH:mm:ss'))
                /** @note if the difference is more than 1 minute seperate the logs */
                if (difference > 0.02) {
                  sessionLogs.push({ // will always be usage log
                    ...item,
                    data,
                    date: moment(item.in).format('YYYY-MM-DD'),
                    in: moment(item.in).format('HH:mm:ss'),
                    out: itemout, // moment(item.out).format('HH:mm:ss'),
                    label: 'usage',
                  });
                } else {
                  prevSessionLog.out = itemout, // moment(item.out).format('HH:mm:ss');
                    prevSessionLog.data += data;
                  prevSessionLog.appName = item.appName;
                  prevSessionLog.windowName = item.windowName;
                  sessionLogs[sessionLogs.length - 1] = prevSessionLog;
                }
              }

              if (it > 0 && usagebool && !idlebool && prevSessionLog['label'] === 'idle') {
                sessionLogs.push({ // will always be idle log
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout, // moment(item.out).format('HH:mm:ss'),
                  label: 'usage',
                });
              }

              // @note for previous usage log and current one being usage as well as with idle, add seperate logs for both.
              if (it > 0 && usagebool && idlebool && prevSessionLog['label'] === 'usage') {
                /** @note add an idle log at the end for time difference. */
                const newOutInTime = this._sharedService.getNewTimeAfterDifference(item.idle, itemout);
                prevSessionLog.out = newOutInTime;
                prevSessionLog.data += data; // will always be usage log
                prevSessionLog.appName = item.appName;
                prevSessionLog.windowName = item.windowName;
                sessionLogs[sessionLogs.length - 1] = prevSessionLog;

                /** @fixme required to fix since causes unncessary padding. */
                // sessionLogs.push({ // will always be idle log, no need for non-usage log in the middle
                //   ...item,
                //   data,
                //   date: moment(item.in).format('YYYY-MM-DD'),
                //   in: newOutInTime,
                //   out: itemout, // moment(item.out).format('HH:mm:ss'),
                //   label: 'idle',
                // });
                // continue;
              }

              // @note for previous idle log and current one being usage as well as with idle, consider usage
              if (it > 0 && usagebool && idlebool && prevSessionLog['label'] === 'idle') {
                const newOutInTime = this._sharedService.getNewTimeAfterDifference(item.usage, itemout);
                prevSessionLog.out = newOutInTime;
                prevSessionLog.data += data; // will always be idle log
                prevSessionLog.appName = item.appName;
                prevSessionLog.windowName = item.windowName;
                sessionLogs[sessionLogs.length - 1] = prevSessionLog;

                sessionLogs.push({ // will always be usage log, no need for non-usage log in the middle
                  ...item,
                  data,
                  appName: item.appName,
                  windowName: item.windowName,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: newOutInTime,
                  out: moment(item.out).format('HH:mm:ss'),
                  label: 'usage',
                });
              }
            }
            newDateLogs[date] = sessionLogs;


          }
        }
        // console.log('tempDataTooltip::: dashboard_after_loop :::newDateLogs:::', newDateLogs, ':::dataset:::', dataset);

        // console.log("newData", newData);

        // Pad arrays to the same length with null values
        this._sharedService.padArraysToSameLength(newDateLogs, { break: 0, working: 0, label: 'nonusage' });


        Object.values(newDateLogs).forEach((dateSess: any, dateSessIn) => {
          dateSess.forEach((sessLog, sessLogIn) => {
            dataset.set(`${sessLogIn}-nonusage`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'nonusage',
              backgroundColor: 'rgba(255,255,255,0)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });

            dataset.set(`${sessLogIn}-idle`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'idle',
              backgroundColor: 'rgba(197,199,235,1)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });

            dataset.set(`${sessLogIn}-usage`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'usage',
              backgroundColor: 'rgba(101,105,213,1)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });
          });

        });
        Object.values(newDateLogs).forEach((dateSess: any, dateSessIn) => { // 7 days
          dateSess.forEach((sessLog, sessLogIn) => { // 11 sessions - 3 log types
            let existingSessLog = dataset.get(`${sessLogIn}-${sessLog.label}`); /** @debug which index should be used? */
            if (existingSessLog) {
              existingSessLog.data[dateSessIn] = sessLog.in ? [this._sharedService.formateTimeInDecimal(sessLog.in), this._sharedService.formateTimeInDecimal(sessLog.out)] : [0, 0];
              dataset.set(`${sessLogIn}-${sessLog.label}`, {
                ...existingSessLog,
                ...sessLog,
                label: sessLog.label,
                data: existingSessLog.data,
              });
            }
          });
        });

        this.stackedChartData = Array.from(dataset.values());
      } else {
        // No Records Found for selected Dates
      }
    } catch (error) {
      // console.error(" ERROR in making stackbar chart data ", error);
    }
  }

  getStackedChartData(start: any, endDate: any, days?: any) {
    this.tempDataTooltip = [];
    this.newSessionTimeline = [];
    // console.log("START DATA FROM STACKED BAR CHART : ", this.stackcurrentWeekArray, this.stackcurrentWeekArray);
    // let days = [];
    this.stackedChartlabel = this.stackcurrentWeekArray;
    this
      ._projectService
      .getUserApplicationLogs(this.userId, start, endDate)
      .subscribe((res: any) => {
        // const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        // Step-1 find min value for the x 
        try {
          if (res) {
            let data: any = {
            };
            for (let i = 0; i <= this.stackcurrentWeekArray.length - 1; i++) {
              let day = this.datePipe.transform(new Date(this.stackcurrentWeekArray[i]), "yyyy-MM-dd");
              if (res[day]) {
                data[day] = res[day]
              } else {
                data[day] = {
                  sessions: []
                };
              }
            }
            let min;
            let max;
            for (let key in res) {
              if (key == "min") {
                //  min = res[key];
                const [hours, minutes, second] = res[key].split(":").map(Number);

                min = this.padZeroes(hours) + "." + this.padZeroes(minutes);
              }
              if (key == "max") {
                if (res[key]) {
                  const [hours, minutes, second] = res[key].split(":").map(Number);

                  max = this.padZeroes(hours) + "." + this.padZeroes(minutes);
                }
              }
            }
            this.makeStackBarChartData(data, min, max)
          }
          else {
            console.error(" ERROR in making stackbar chart data ");
          }
        } catch (error) {
          console.error(" ERROR in making stackbar chart data ", error);
        }
      }, (error) => {
        this.stackedChartData = [{ data: [] }];
      });
  }
  padZeroes(value: number) {
    return value.toString().padStart(2, '0');
  }
  addName(id) {
    if (this.screenCastRequestStatus == true) {
      this.screenCastImage = "data:image/png;base64," + id.image;
    } else {
      this.screenCastImage = null;
    }
    // this.names.push(value);
  }

  getUserDetails() {
    // console.log(this.userId, "userID");
    this._projectService.getEmployeeData(this.userId).subscribe(
      (data: any) => {
        // console.log(data.name, data.slug, "data");
        if (!data) {
          data = {
            user: {
              id: this.userId,
            },
            status: "offline",
          };
        }
        this.userDetails = data;
        this.loading = false;
      },
      (err) => {
        if (err.status === 403) {
          this._router.navigate(['/employees']);
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        }
        console.error("ERROR WHILE GET EMPLOYEE DATA");
      }
    );
  }

  onclicktab(select) {
    // console.log(this.selectedTabIndex + "selectedTabIndex");
    if (this.ismodelOpen) {
      this.isScreenCast = true;
    }
  }

  selectedTabValue(event) { }

  requestScreenCast(userId) {
    this.openScreencastActionModel();

    // this.screenCastRequestStatus = true;
    // this.db.database.ref(`${this.dbPath}`).child(`${userId}`).update({scRequestStart: true})
    // this._loginService.requestScreenCast({ user: userId });
    // this._projectService.screencaseRequestAction(true)
  }

  requestScreenCastOff(userId) {
    this.screenCastRequestStatus = false;
    this._projectService.screencaseRequestAction(false);
    this._loginService.requestScreenCastOff({ user: userId });
    this.db.database
      .ref(`${this.dbPath}`)
      .child(`${userId}`)
      .update({ scRequestStop: true });
    this.screenCastImage = null;
  }

  openScreencastActionModel() {
    // console.log("Inside request admin ts");
    //  this._projectService.openScreencastModa();
    let obj = {
      screenCastImage: this.screenCastImage,
      userDetails: this.userDetails,
      db: this.db,
      dbPath: this.dbPath,
      userId: this.userId,
    };
    let data = obj;
    const dialogRef = this.openDialog(
      ScreencastActionModelComponent,
      data

      //maxHeight: "calc(100vh - 90px)",
    ).subscribe((response) => { });
  }

  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }

  @HostListener("click", ["$event"])
  public onClick(event: any) {
    // console.log(event, this.selectedTabIndex, "screen screen");
    if (this.isScreenCast == true && event.target.id != "start-SC") {
      if (
        event.target.outerText == "Application Usages" ||
        event.target.outerText == "Screen Shot" ||
        event.target.outerText == "Time logs" ||
        event.target.outerText == "Super\nSee"
      ) {
        this.openScreencastActionModel();
      }
      if (event.target.localName == "li") {
        this.openScreencastActionModel();
      }
    }
  }

  EditUser() {
    this._sharedService.isEdit = true;
    localStorage.setItem("selectedUserId", this.userId);
    this._router.navigate(["/create-user"], {
      queryParams: { groupId: this.userId },
    });
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData()
  }
  getstatus() {
    this.usersRef = this.db.list(this.dbPath);
    // this.getAll()
    //   .snapshotChanges()
    //   .pipe(
    //     map((changes) =>
    //       changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
    //     )
    //   )
    this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((o) => {
        if (o.id == this.userId) {
          // console.log("status are getted , Response : ", o);
          if (o.lastOnlineAtTz) {
            this.lastActiveTimeTz = o.lastOnlineAtTz;
            this.lastActiveTimeTz = this.customtimeAgoPipe.transform(o.lastOnlineAtTz, 'lastActive');
            this.status = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
          } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
            this.lastActiveTime = o.lastOnlineAt;
            this.lastActiveTime = this.customtimeAgoPipe.transform(o.lastOnlineAt);
            this.status = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
          } else {
            this.status = 'not-using';
          }

          if (o.logoutReason == 'timeOrTimezoneChange') {
            this.logoutReason = 'Time or Timezone Change'
          }
        }
      });
    });
  }

  screenshotRequest() {
    // console.log(this.status, this.dbPath, this.userId, "staaaaatuss");
    if (this.status == "online") {
      let data = {
        type: "Requesting Screenshot",
        userName: this.userDetails.name
      };
      this._sharedService.showToast(data);
      setTimeout(() => {
        this.db.database.ref(`${this.dbPath}`).child(`${this.userId}`).get().then((res) => {
          // console.log("res: while requesting ss", res.val())
          if (res.val().ssRequest == null) {
            this.db.database
              .ref(`${this.dbPath}`)
              .child(`${this.userId}`)
              .update({ ssRequest: false });
            let data = {
              type: "Cant get screenshot",
              userName: this.userDetails.name,
            };
            this._sharedService.showToast(data);
          }
        });
      }, 20000)

      this.db.database
        .ref(`${this.dbPath}`)
        .child(`${this.userId}`)
        .update({ ssRequest: null });
    }
  }

  convertTimeIntoFloat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const floatingValue = parseFloat(
      `${hours == "00" ? "24" : hours}.${minutes}`
    ).toFixed(2);
    return floatingValue;
  }

  addTimes(timeArr) {
    const totalMilliseconds = timeArr
      .map((timeStr) => {
        const timeParts = timeStr.split(":");
        return (
          parseInt(timeParts[0]) * 3600000 +
          parseInt(timeParts[1]) * 60000 +
          parseInt(timeParts[2]) * 1000
        );
      })
      .reduce((acc, curr) => acc + curr, 0);

    const hours = Math.floor(totalMilliseconds / 3600000);
    const minutes = Math.floor((totalMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((totalMilliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  timeToFloat(time: any) {
    // Split the time into hours, minutes, and seconds
    // console.log("time", time);
    // console.log("time splits : ", time.split(":"))

    // const hours = time

    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Calculate the total time in seconds
    const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

    // Convert to a floating-point value (if needed)
    return totalTimeInSeconds / 3600; // Divide by 3600 to get hours as a floating-point value
  }

  onStartDateChange(event) {
    let date = event.value;
    this.startDate = this.datePipe.transform(date, "yyyy-MM-dd");
    // console.log(
    //   "date Changing Start :: ",
    //   this.datePipe.transform(date, "yyyy-MM-dd")
    // );
    // this.startDate = this.startDate ? this.startDate : this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    // this.endDate = this.endDate ? this.endDate : this.datePipe.transform(this.endDate, "yyyy-MM-dd");

    // console.log(this.userDetails);
  }

  onEndDateChange(event) {
    let date = event.value;
    this.workingHours = null;
    this.endDate = this.datePipe.transform(date, "yyyy-MM-dd");
    // this.startDate = this.startDate ? this.startDate : this.datePipe.transform(this.startDate, "yyyy-MM-dd")
    // this.endDate = this.endDate ? this.endDate : this.datePipe.transform(this.endDate, "yyyy-MM-dd")
    // console.log(this.startDate, " ", this.endDate);
    if (this.startDate && this.endDate) {
      this.sendingStartDate = this.startDate;
      this.sendingEndDate = this.endDate;
      // this.getBarChartData(this.startDate, this.endDate);
      this.getPieChartData(this.startDate, this.endDate);
      // this.getStackedChartData(this.startDate, this.endDate);

    }
  }

  gettingDataFilter(data) {
    let logsArr = [];
    let applicationNames = [];
    for (let key in data) {
      data[key].logs.map((ele) => {
        logsArr.push(ele);
      });
      // console.log(data[key]);
    }
    for (let key in data) {
      applicationNames.push(key);
    }
    // console.log(logsArr, " ", applicationNames);
    return { logs: logsArr, apps: applicationNames };
  }

  timeToNumber(timeString) {
    // Your time value
    // var timeString = "08:30:00";

    // Split the time string into hours, minutes, and seconds
    var timeParts = timeString.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Calculate the decimal representation
    var decimalValue = hours + minutes / 60;

    // Round the decimal value to two decimal places
    decimalValue = Math.round(decimalValue * 100) / 100;
    return decimalValue;
  }

  getUserCurrentStatus(lastActiveTime: any, isUtCTimeZone: any) {
    if (isUtCTimeZone) {
      this.status = lastActiveTime ? this._sharedService.getUserCurrentStatus(lastActiveTime, true) : 'not-using';
    } else {
      this.status = lastActiveTime ? this._sharedService.getUserCurrentStatus(lastActiveTime, false) : 'not-using';
    }
    return this.status
  }
  getWeek(e) {
    if (e == 0) {
      this.currentDate = moment();
    } else {
      this.currentDate.add(e, 'weeks');
    }

    // console.log('week number ', this.currentDate.week());

    let weekStart = this.currentDate.clone().startOf('week');
    let weekEnd = this.currentDate.clone().endOf('week');

    // console.log('weekStart ', weekStart, weekStart.format('YYYY-MM-DD'))
    // console.log('weekEnd ', weekEnd)

    let days = [];
    let now = weekStart.clone()
    while (now.isSameOrBefore(weekEnd)) {
      days.push([now.format('dddd'), now.format('YYYY-MM-DD')]);
      now = now.add(1, 'days');
    }
    // console.log('days vs', days, weekStart);
    // this.getStackedChartData(weekStart.format('YYYY-MM-DD'), weekEnd.format('YYYY-MM-DD'), days);
  }

  trimName(name) {
    const trimmedName = name.length <= 20 ? name : name.slice(0, 20) + (name.length > 20 ? '...' : '');
    return trimmedName;
  }
}

<div class="login_section">
	<div class="form_section">
		<form [formGroup]="signupForm">
			<div class="logo" (click)="openSupersee()">
				<img src="../../../assets/icons/icon-144x144.png">
				<h4>Super<strong style="font-weight: 700;">See</strong></h4>
			</div>
			<div class="welcome-contain">
				<label>Claim Your Free Trial</label>
				<p>Start your free trial of <span> 14 Days</span> today and experience the future of productivity!</p>
				<div>No credit card required</div>
			</div>
			<div class="input_sections">
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Enter company name</mat-label>
						<input matInput formControlName="company" placeholder="Enter company name">
					</mat-form-field>
					<mat-error *ngIf="
					   signupForm.get('company')?.hasError('required') &&
						(signupForm.get('company')?.dirty ||
					 signupForm.get('company')?.touched)">
						Please enter company name </mat-error>
				</div>
				<div class="input_box">
					<div class="row" style="margin: 0px !important;">
						<div class="col s12 m6 small_box" style="padding: 0px ">
							<mat-form-field class="mat-form" appearance="outline">
								<mat-label>First name</mat-label>
								<input matInput formControlName="firstName" placeholder="First name">
							</mat-form-field>
							<mat-error *ngIf="
							   signupForm.get('firstName')?.hasError('required') &&
								(signupForm.get('firstName')?.dirty ||
							 signupForm.get('firstName')?.touched)">
								Please enter first name </mat-error>
						</div>
						<div class="col s12 m6 small_box" style="padding: 0px ">
							<mat-form-field class="mat-form" appearance="outline">
								<mat-label>Last name</mat-label>
								<input matInput formControlName="lastName" placeholder="Last name">
							</mat-form-field>
							<mat-error
								*ngIf="signupForm.get('lastName')?.hasError('required') && (signupForm.get('lastName')?.dirty || signupForm.get('lastName')?.touched)">
								Please enter last name </mat-error>
						</div>
					</div>
				</div>
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Enter your work email</mat-label>
						<input matInput formControlName="email" placeholder="Enter your work email"
							[readonly]="isGoogleSignIn ? true : false">
					</mat-form-field>
					<mat-error
						*ngIf="signupForm.get('email')?.hasError('required') && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)">
						Please enter your work email </mat-error>
					<mat-error
						*ngIf="signupForm.get('email')?.hasError('pattern') && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)">
						Please enter a valid email address</mat-error>
				</div>
				<div class="input_box">
					<div class="row align-items-center" style="width: 100%;">
						<!-- Country Dropdown -->
						<div class="country-dropdown">
							<lib-country-list [selectedCountryConfig]="selectedCountryConfig"
								[countryListConfig]="countryListConfig" (onCountryChange)="onCountryChange($event)"
								[selectedCountryCode]="'in'">
							</lib-country-list>
						</div>
						<!-- Contact Number Input -->
						<div style="width: 100%;">
							<mat-form-field class="mat-form" appearance="outline">
								<mat-label>Enter contact number</mat-label>
								<input matInput type="number" #contact_Val formControlName="contact"
									placeholder="Enter your contact number">
							</mat-form-field>
							<mat-error
								*ngIf="signupForm.get('contact')?.hasError('required') && (signupForm.get('contact')?.dirty || signupForm.get('contact')?.touched)">
								Please enter contact number </mat-error>
							<mat-error
								*ngIf="signupForm.get('contact')?.hasError('pattern') && (signupForm.get('contact')?.dirty || signupForm.get('contact')?.touched)">
								Please enter valid number </mat-error>
						</div>
					</div>
				</div>
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Enter password</mat-label>
						<input matInput formControlName="password" placeholder="Enter Password"
							[type]="hide ? 'password' : 'text'" autocomplete="new-password">
						<button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide">
							<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('required') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password is required.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('minlength') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must be at least 8 characters long.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('maxlength') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must not exceed 20 characters.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('space') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must not start or end with a spaces.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('uppercase') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must contain at least one uppercase letter.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('lowercase') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must contain at least one lowercase letter.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('number') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must contain at least one number.
					</mat-error>
					<mat-error
						*ngIf="signupForm.get('password')?.hasError('special') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
						Password must contain at least one special character. <br>
						Allowed special characters: ! &quot; # $ % &amp; &apos; ( ) * + , - . / : ; &lt;
						= &gt; ? &#64; [ \ ] ^ _ {{ '{' }} {{ '}' }} | ~
					</mat-error>
				</div>
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Confirm password</mat-label>
						<input matInput formControlName="confirmpassword" [type]="visible ? 'password' : 'text'"
							placeholder="Enter Password">
						<button mat-icon-button matSuffix class="passBtn" (click)="visible = !visible">
							<mat-icon>{{visible ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
					<mat-error
						*ngIf="
					signupForm.get('confirmpassword')?.hasError('required') && (signupForm.get('confirmpassword')?.dirty || signupForm.get('confirmpassword')?.touched)">
						Please enter confirm password </mat-error>
					<mat-error
						*ngIf="signupForm.get('password').value != signupForm.get('confirmpassword').value  && (signupForm.get('confirmpassword')?.dirty || signupForm.get('confirmpassword')?.touched) && signupForm.errors?.mismatch">
						Password does not match </mat-error>
				</div>
				<div class="button_section">
					<button mat-raised-button [disabled]="!signupForm.valid || selectedCode == '' || isDisable"
						(click)="signUpAction()" class="btn signInBtn" extended>
						<span>
							<span
								[ngStyle]="{'visibility': isDisable ?'hidden':'visible','float': 'inline-end','margin-left':'-50px'}">Sign
								Up</span>
						</span>
						<span class="signInBtn_loading">
							<span class="loading_text"
								[ngStyle]="{'visibility': !isDisable ?'hidden':'visible','margin-left':'-50px'}">Sign Up
								you in</span>
							<span class="signInBtn--loading"
								[ngStyle]="{'visibility': !isDisable ?'hidden':'visible','position':'relative','margin-left': '10px'}"></span>
						</span>
					</button>
				</div>
				<div class="link_section">
					<div class="divider-contain">
						<div class="divider"><mat-divider></mat-divider></div>
						<div class="or">
							<p>Or</p>
						</div>
						<div class="divider"><mat-divider></mat-divider></div>
					</div>
					<p>Already have an account? <a (click)="gotoSignIn()">Log In</a></p>
				</div>
			</div>
		</form>
	</div>
	<div class="image_section">
		<img src="../../assets/signup.png">
	</div>
</div>
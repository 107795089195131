<!-- <app-header></app-header> -->
<div class="banner" *ngIf="!expired">
  <quota-banner [DeleteEmployee]="deleteUSer"></quota-banner>
</div>
<div class="container-fluid">
  <!-- header section -->
  <!-- <div class="row ">
    <div class="col s6">
      <h1>Employee List</h1>
    </div>
    <div class="col s6 headerDiv">
      <div style="margin-right: 18px;">
        <mat-form-field class="mat-form search-input">
          <mat-label>Search Employee</mat-label>
          <input
            matInput
            placeholder="Enter Employee Name"
            #searchInp
            (keyup)="searchEmployee(searchInp.value)"
          />
        </mat-form-field>
      </div>
      <div>
        <button
          mat-raised-button
          class="btn deep-purple btn-add-emp modal-trigger"
          (click)="openUserCreateModal(userModal)"
        >
          <span
            style="margin-right: 6px; font-size: 23px"
            class="material-symbols-outlined"
          >
            person_add
          </span>
          New Employee
        </button>
      </div>
    </div>
  </div> -->
  <!-- <div class="trialVersionDiv" *ngIf="!isTrialBanner">
    <div class="info_section">
      <div class="icon_section">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0002 0.25C8.07188 0.25 6.18682 0.821828 4.58344 1.89317C2.98006 2.96451 1.73038 4.48726 0.992423 6.26884C0.254469 8.05042 0.0613861 10.0108 0.437592 11.9021C0.813798 13.7934 1.7424 15.5307 3.10596 16.8943C4.46952 18.2579 6.2068 19.1865 8.09812 19.5627C9.98943 19.9389 11.9498 19.7458 13.7314 19.0078C15.513 18.2699 17.0357 17.0202 18.1071 15.4168C19.1784 13.8134 19.7502 11.9284 19.7502 10C19.7473 7.41504 18.7192 4.93679 16.8913 3.10894C15.0635 1.28109 12.5852 0.252926 10.0002 0.25ZM9.8128 4.75003C10.0353 4.75003 10.2528 4.81601 10.4378 4.93963C10.6228 5.06325 10.767 5.23895 10.8522 5.44452C10.9373 5.65008 10.9596 5.87628 10.9162 6.09451C10.8728 6.31274 10.7656 6.5132 10.6083 6.67053C10.451 6.82786 10.2505 6.93501 10.0323 6.97842C9.81405 7.02183 9.58785 6.99955 9.38228 6.9144C9.17671 6.82925 9.00101 6.68506 8.8774 6.50005C8.75378 6.31505 8.6878 6.09754 8.6878 5.87503C8.6878 5.57667 8.80633 5.29052 9.0173 5.07954C9.22828 4.86856 9.51443 4.75003 9.8128 4.75003ZM10.7503 15.25H10.0003C9.80134 15.25 9.61059 15.171 9.46994 15.0303C9.32928 14.8897 9.25026 14.6989 9.25025 14.5V10C9.05134 10 8.86058 9.92098 8.71992 9.78033C8.57927 9.63968 8.50025 9.44891 8.50025 9.25C8.50025 9.05109 8.57927 8.86032 8.71992 8.71967C8.86058 8.57902 9.05134 8.5 9.25025 8.5H10.0003C10.1992 8.50001 10.3899 8.57903 10.5306 8.71968C10.6712 8.86033 10.7502 9.05109 10.7503 9.25V13.75C10.9492 13.75 11.1399 13.829 11.2806 13.9697C11.4212 14.1103 11.5003 14.3011 11.5003 14.5C11.5003 14.6989 11.4212 14.8897 11.2806 15.0303C11.1399 15.171 10.9492 15.25 10.7503 15.25Z"
            fill="#21BC68" />
        </svg>
      </div>
      <div class="trialLabel">
        <p style="margin: 0px;">Trial version</p>
        <div class="trialSubLabel">
          {{ daysRemaining}} days remaining
          <span>({{availableUser}}/{{totalUser}} user left)</span>
        </div>
      </div>
    </div> -->
  <!-- <div class="btn_group_trial">
      <button class="trialBtnIncreaseLimit" (click)="IncreaseLimitAction()">Increase Limit</button>
      <svg xmlns="http://www.w3.org/2000/svg" class="close_icon" viewBox="0 0 30 30" width="30px" height="30px" (click)="trialCloseBanner()">
        <path
          d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
      </svg>
    </div> -->
  <!-- </div> -->

  <!-- <div class="expVersionDiv" *ngIf="!isExpiredBanner">
    <div class="info_section">
      <div class="icon_section">
        <i class="material-icons warn_icon">warning</i>
      </div>
      <div class="trialLabel">
        <p style="margin: 0px;" *ngIf="quotaStatus=='trial-expired'">Your plan about to expire</p>
        <p style="margin: 0px;" *ngIf="quotaStatus=='expired'">Your plan has expired</p>
        <div class="trialSubLabel" *ngIf="quotaStatus=='trial-expired'">You are having {{availableUser}} user left in your current plan</div>
        <div class="trialSubLabel" *ngIf="quotaStatus=='expired'">renew it to regain access</div>
      </div>
    </div>
    <div class="btn_group_trial">
      <p class="warn_section" style="margin: 0px;">Click to <a href="Https://supersee.io"
          target="_blank">renew plan</a></p>
      <svg xmlns="http://www.w3.org/2000/svg" class="close_icon" viewBox="0 0 30 30" width="30px" height="30px" (click)="trialCloseBanner()">
        <path
          d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
      </svg>
    </div>
  </div> -->

  <div class="row employee-list-container">
    <div class="col s12 l5 xl6">
      <h1>Employee List</h1>
    </div>
    <div class="col s12 l7 xl6 employee-list-search-container">
      <div class="search-bar">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
            fill="#B3B3B3" />
        </svg>

        <input matInput #searchInp (keyup)="searchEmployee(searchInp.value)" placeholder="Search here">
      </div>
      <span *ngIf="!expired"
        matTooltip="{{quota_remaining == 0 ? 'Upgrade your plan as there is no remaining user quota.' : ''}}">
        <button mat-raised-button [disabled]="!isTourActive() || quota_remaining == 0" class="employee-add-btn"
          (click)="openUserCreateModal(userModal)" tourAnchor="addNewEmployee"
          [ngStyle]="{'visibility':(isAdmin && !isQuota) || !isTourActive() ? 'visible' : 'hidden','display':(isAdmin && !isQuota) || !isTourActive() ? 'block' : 'none'}">
          <!-- <span class="col s3" matTooltip="tooltip" style="text-align: end;"></span> -->
          <span class="material-symbols-outlined">
            person_add
          </span>
          New Employee
        </button>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="s12 col">
      <div>
        <div class="row dropdown-row">
          <div class="col s12">

          </div>
        </div>
        <app-users-table [expired]="expired" [developerList]="developerList" [loadWaiter]="stopLoading"
          [modalRef]="userModal" (modalOpen)="openUserModal($event)" (deleteEmployeee)="deleteEmployee()"
          [showAction]="true"></app-users-table>
      </div>
      <div>
        <mat-paginator [length]="totalUsers" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
          (page)="onPaginateChange($event)" #EmpPaginator
          [ngStyle]="{'visibility':developerList && developerList.length ? 'visible' : 'hidden'}">
        </mat-paginator>
      </div>
    </div>
  </div>

</div>

<!-- id="addEmpModal" -->
<div #userModal class="modal">
  <app-create-user [isEditable]="{id: editUserId, isEdit: isUserEditable}"></app-create-user>
</div>

<!-- <app-loader *ngIf="loading"></app-loader> -->
<!-- <div class="viewMorebDiv">
  <button class="btn waves-effect waves-light deep-purple
  btn-add-emp modal-trigger" (click)="btnViewMoreAction()" [disabled]="!isViewMore">
    <span style="margin-right: 6px; font-size: 23px;" class="material-symbols-outlined">
      refresh
    </span>
    View More
  </button>
</div> -->
import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { DatePipe, ViewportScroller } from "@angular/common";
import * as moment from "moment";
import { ProjectService } from "src/app/Services/project.service";
import { Observable, Subscription, observable, of } from "rxjs";
import { UntypedFormControl, FormGroup, FormControl } from "@angular/forms";
import * as _ from "lodash";
import { config } from "src/app/config";
import { SharedService } from "src/app/Services/sharedService";

import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { MatPaginator } from "@angular/material/paginator";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { WelcomeComponent } from "src/app/welcome/welcome.component";
import { TourConfigService } from "src/app/Services/tour-config.service";
import { event } from "jquery";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";

declare var $;
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const date = today.getDate();

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
}
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [DatePipe],
})
export class DashboardComponent {
  startPage = 1;
  endPage = 10;
  totalPage = 1;
  totalStack_user = 0;
  offlinePercentage: any = 0;
  onlinePercentage: any = 0;
  stackedArr;
  selectedEmp;
  isloading = false;
  startDate: any;
  chartdata;
  filterTeamStackArr = [];
  endDate: any = new Date();
  enddate = new Date();
  isPiechartLoad = false;
  onlinePercentageIcon = "north"
  offlinePercentageIcon = "north"
  searchStack = '';
  startdate;
  stackDate;
  maxDate = new Date();
  users = [];
  userIds = [];
  totalInactiveTime = 0;
  totalWorkHours = 0;
  totalProductiveHours = 0;
  donutChartData: any = [];
  pieChartdata: any = [];
  stackedChartData;
  searchText: string;
  userName = "";
  userId;
  PhotoList: any = [];
  filteredData: Observable<any[]>;
  filterControl = new UntypedFormControl();
  onlineUsers = 0;
  offlineUsers = 0;
  totalUsers = 0;
  filePath = config.baseMediaUrl;
  removable = true;
  loading = false;
  page = 1;
  isData = true;
  teamList = [];
  newSessionTimeline: any = [];
  userDataSubscription: Subscription;
  startdate_ss: any;
  enddate_ss: any;
  @ViewChild("stateInput", { static: true }) stateInput!: ElementRef;
  @ViewChild("EmpPaginator", { static: false }) EmpPaginator: MatPaginator;
  usedAppInfo: any;
  pieChartLabels: any = [];
  barChartLast10Data: any = [];
  barChartLast10Labels: any = [];
  barChartTop10Data: any = [];
  barChartTop10Labels: any = [];
  selectedOptions: any = [];
  showError = false;
  errorMessage = "";
  selected: any;
  filterUsers: any[];
  userList = [];
  isShowPieChart = true;
  usersRef: AngularFireList<User> = null;
  private dbPath = localStorage.getItem('dbPath');
  userImageClass = 'avatar-img';

  public searchMultiCtrl: FormControl = new FormControl();
  public searchCtrl: FormControl = new FormControl();
  pieChartData: any[];
  pieChartLabel: any[];
  pieChartDisplayData: any[];
  // donutChartData: any[];
  donutChartLabel: any[];
  donutChartDisplayData: any[];
  x_min: any;
  stackedChartlabel: any = [];
  tempDataTooltip: any = [];
  stackedChartIds: any = [];

  x_max: any;
  _isStackbarLoading: boolean = false;
  _isTotalHoursLoading: boolean = false;
  _isAppUsageLoading: boolean = false;

  workingHoursColor = ['#ff6f61', '#ffcc5c']
  appUsageColor = ['#ff6f61', // Red
    '#ffcc5c', // Gold
    '#ffa07a', // Light Salmon
    '#ffeb99', // Pale Goldenrod
    '#98fb98', // Pale Green
    '#00ced1', // Dark Turquoise
    '#87ceeb', // Sky Blue
    '#6495ed', // Cornflower Blue
    '#9370db', // Medium Purple
    '#c71585', // Medium Violet Red
  ]
  workingHours: any;
  appUsageHours: any;
  timelineSection = true;
  screenshotSection = false;
  timelineBackground = 'timeline-section-btn-bg-color';
  screenshotBackground = '';
  rencetScreenshots;
  total_ss = 0;
  pageSize = 25;
  offset = 0;
  currentPage = 1;
  scrollTarget = '';
  teamsListForFilter: any = [];
  selectedTeam: any;
  currentTeam = '';
  locale: any = {
    applyLabel: 'Apply',
    format: 'YYYY-MM-DD HH:mm:ss',
    displayFormat: 'YYYY-MM-DD HH:mm:ss',
    separator: ' - ',
    cancelLabel: 'Cancel',
    customRangeLabel: 'Custom Range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek()
  };
  selectedDateAndTime: { startDate: moment.Moment, endDate: moment.Moment };

  selectFormControler = new FormControl();

  private _currentState;
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  constructor(
    private datePipe: DatePipe,
    private _projectService: ProjectService,
    private _sharedService: SharedService,
    private db: AngularFireDatabase,
    private _messagingService: MessagingServiceService,
    private _sharedServices: SharedService,
    private scroller: ViewportScroller,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private router: Router,
    public dialog: MatDialog,
    private _tourService: TourConfigService,
  ) {
    if (this.isTourActive()) {
      this.getUser()
    }
    const currentUrl = this.router.url;
    if (currentUrl != '/dashboard' && currentUrl != '/') {
      this.router.navigateByUrl('dashboard');
    }

    // if(localStorage.getItem('tourStart')){
    //   if(localStorage.getItem('tourStart') == 'true'){
    //     const dialogRef = this.openDialog(
    //       WelcomeComponent
    //     ).subscribe((response) => { });
    //   }
    // }
    _messagingService.showNotification();
    this._sharedServices.sideMenuSubject.next(true);

    // this._sharedServices.isAdminLogin.next(true);
    this._projectService.updateAdminLoginData({ data: true })
    this.startdate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    );
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    );
    this.stackDate = this.enddate;
    //this.startdate = start
    this.usersRef = db.list(this.dbPath);

    this.maxDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );


    this.getTotalOnlineOflineData();
    this.getTeam();
    this.getUserList();
    this.getLastScreenshots();
    this.getLastFiftyPhotos();
    _projectService.dashboardTourActive.subscribe((value: any) => {

      this.timelineSection = true;
      this.screenshotSection = false;
      this.getPieChartData(
        this.datePipe.transform(this.startdate, "yyyy-MM-dd"),
        this.datePipe.transform(this.enddate, "yyyy-MM-dd")
      );
      this.getStackedChartData(
        this.datePipe.transform(this.stackDate, "yyyy-MM-dd"), this.searchStack
      )
    })
  }
  ngOnDestroy() {
    this.isShowPieChart = false;
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData();
  }
  ngOnInit() {
    document.getElementById("pieChartContainer")
      ? document.getElementById("pieChartContainer").remove()
      : "";
    document.getElementById("chartContainer")
      ? document.getElementById("chartContainer").remove()
      : "";

    document.getElementById("donutChartNewContainer")
      ? document.getElementById("donutChartNewContainer").remove()
      : "";
    document.getElementById("chartContainer2")
      ? document.getElementById("chartContainer2").remove()
      : "";

    this._sharedServices.dashboardScroller().subscribe((result: any) => {
      if (result == 'ss_section') {
        this.timelineSection = false;
        this.screenshotSection = true;
        this.timelineBackground = '';
        this.screenshotBackground = 'timeline-section-btn-bg-color';
        this.scroller.scrollToAnchor('timeline_ss_section');
      } else if (result == 'timeline_section') {
        this.timelineSection = true;
        this.screenshotSection = false;
        this.timelineBackground = 'timeline-section-btn-bg-color';
        this.screenshotBackground = '';
        this.scroller.scrollToAnchor('timeline_ss_section');
      } else {
        this.scroller.scrollToAnchor(result);
      }
    });
    if (this.isTourActive()) {

      this._projectService.getTeamList().subscribe(
        (team: any) => {
          this.loading = false;
          if (team.length == 0) {
            this.teamsListForFilter = [];
          }
          this.teamsListForFilter = team;
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }
  getUser() {
    this._projectService.getUser().subscribe((data: any) => {
      localStorage.setItem('user', JSON.stringify(data))
      let isExpired = this._sharedService.checkPlanExpired(data)
      if (isExpired) {
        this.router.navigateByUrl('/plan-expired');
      }
    })
  }
  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  ngAfterViewInit() {
    if (!this.isTourActive()) {
      this.getTotalHour(
        this.datePipe.transform(this.startdate, "yyyy-MM-dd"),
        this.datePipe.transform(this.enddate, "yyyy-MM-dd")
      );
      this.getPieChartData(
        this.datePipe.transform(this.startdate, "yyyy-MM-dd"),
        this.datePipe.transform(this.enddate, "yyyy-MM-dd")
      );

    }

    this._sharedServices.dashboardScroller().subscribe((result: any) => {
      if (result == 'ss_section') {
        this.timelineSection = false;
        this.screenshotSection = true;
        this.timelineBackground = '';
        this.screenshotBackground = 'timeline-section-btn-bg-color';
        this.scroller.scrollToAnchor('timeline_ss_section');
      } else if (result == 'timeline_section') {
        this.timelineSection = true;
        this.screenshotSection = false;
        this.timelineBackground = 'timeline-section-btn-bg-color';
        this.screenshotBackground = '';
        this.scroller.scrollToAnchor('timeline_ss_section');
      } else {
        this.scroller.scrollToAnchor(result);
      }
    });
  }

  filterStates(name: string) {
    if (name) this.userName = "";

    return this.users.filter(
      (state) =>
        state._id != null &&
        state.name &&
        state.name.toLowerCase().includes(name.toLowerCase().trim())
    );
  }
  onEnter(evt: any, name) {
    if (this.users.includes(name)) return;
    if (evt != null) {
      this.userId = evt;
    }
    this.userName = name;
    this.users.push(name);
    this.stateInput.nativeElement.value = "";
    this.filterControl.setValue(null);
    this.removable = true;
  }

  onKeyUp() {
    this.userName = "";
  }

  displayFn(option) {
    if (option && option != undefined) {
      return option;
    }
  }

  onCardClick(type: any) {
    switch (type) {
      case "total":
        localStorage.setItem("empCount", JSON.stringify(this.totalUsers));
        this._projectService.empCountSubject.next(this.totalUsers);
        break;
      case "online":
        localStorage.setItem("empCount", JSON.stringify(this.onlineUsers));
        this._projectService.empCountSubject.next(this.onlineUsers);
        break;
      case "offline":
        localStorage.setItem("empCount", JSON.stringify(this.offlineUsers));
        this._projectService.empCountSubject.next(this.offlineUsers);
        break;
    }
  }

  getUserList() {
    this._isTotalHoursLoading = true
    this._isStackbarLoading = true
    this._isAppUsageLoading = true
    this.isloading = true;


    if (this.isTourActive()) {

      this
        ._projectService
        .getUserList(0, 0).subscribe((user: any) => {
          this.userList = user;
          this.filterUsers = this.userList;
          this.loading = false;
          this.isloading = false;

          this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
            let online = 0;
            let offline = 0;
            if (this.userList) {
              this.userList.forEach((wpEmployee: any) => {

                const index = _.findIndex(users, function (fbEmployee: any) { return fbEmployee.id == wpEmployee.id; });

                if (index > -1) {
                  if (users[index].lastOnlineAtTz) {
                    const currentStatus = this._sharedService.getUserCurrentStatus(users[index].lastOnlineAtTz, true)
                    if (currentStatus == 'online') online++
                    else offline++
                  } else {
                    if (users[index].lastOnlineAt) {
                      const currentStatus = this._sharedService.getUserCurrentStatus(users[index].lastOnlineAt, false)
                      if (currentStatus == 'online') online++
                      else offline++
                    }
                  }

                }
                else offline++
              })
              if (this.onlineUsers != online || this.offlineUsers != offline) {
                this.getTotalOnlineOflineData();
              }
              this.offlineUsers = offline
              this.onlineUsers = online
            }

            this.loading = false;
            this.isloading = false;
          }, err => {
            this.isloading = false;
          });
          this.page = 1;

          this.getTotalHour(
            this.datePipe.transform(this.startdate, "yyyy-MM-dd"),
            this.datePipe.transform(this.enddate, "yyyy-MM-dd")
          );
          this.getPieChartData(
            this.datePipe.transform(this.startdate, "yyyy-MM-dd"),
            this.datePipe.transform(this.enddate, "yyyy-MM-dd")
          );
          this.getStackedChartData(
            this.datePipe.transform(this.enddate, "yyyy-MM-dd"), this.searchStack
          );
        });
    } else {
      this.loading = false;
      this.isloading = false;
      this.page = 1;
      this.getStackedChartData(
        this.datePipe.transform(this.enddate, "yyyy-MM-dd"), this.searchStack
      );
    }
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  getLastFiftyPhotos() {
    // this.PhotoList = [];
    if (this.isTourActive()) {

      this._projectService
        .getlastFifteenPhoto(this.selectedEmp, 50, this.offset, 0, this.selectedDateAndTime && this.selectedDateAndTime.startDate ? this.selectedDateAndTime.startDate.format('YYYY-MM-DDTHH:mm:ss') : null, this.selectedDateAndTime && this.selectedDateAndTime.endDate ? this.selectedDateAndTime.endDate.format('YYYY-MM-DDTHH:mm:ss') : null)
        .subscribe(
          (res: any) => {
            console.log(res.headers.get('X-WP-Total'), "totalllll")
            // let photos = this.getCorrectData(res);
            // this.rencetScreenshots = photos;
            this.total_ss = res.headers.get('X-WP-Total') ? res.headers.get('X-WP-Total') : 0;
            if (!this.selectedDateAndTime || !this.selectedDateAndTime.startDate || !this.selectedDateAndTime.endDate) {
              if (this.total_ss > 50) {
                this.total_ss = 50;
              }
            }
          },
          (err) => { }
        );
    }
  }

  getLastScreenshots() {
    if (this.isTourActive()) {
      this.isloading = true;
      this.PhotoList = [];

      this._projectService
        .getlastFifteenPhoto(this.selectedEmp, this.pageSize, 0, this.currentPage, this.selectedDateAndTime && this.selectedDateAndTime.startDate ? this.selectedDateAndTime.startDate.format('YYYY-MM-DDTHH:mm:ss') : null, this.selectedDateAndTime && this.selectedDateAndTime.endDate ? this.selectedDateAndTime.endDate.format('YYYY-MM-DDTHH:mm:ss') : null)
        .subscribe(
          (res) => {
            let photos = this.getCorrectData(res);
            this.PhotoList = photos;
            console.log(this.PhotoList, "photo---")
            this.isloading = false;
          },
          (err) => {
            this.isloading = false;
          }
        );
    }
  }

  displayUserName(id) {
    let resData = this.userList.filter((data: any) => {
      let userNameArr = data.name.split(" ");
      for (let i = 0; i < userNameArr.length; i++) {
        userNameArr[i] = userNameArr[i].charAt(0).toUpperCase() + userNameArr[i].slice(1);
      }
      data.name = userNameArr.join(" ");
      return data.id == id;
    });
    return resData.length ? resData[0].name : "";
  }

  imageNameAndDate(image, type) {
    return this._sharedService.imageNameAndDate(image, type);
  }

  getFileName(ss) {
    if (ss.path) ss = ss.path;
    return ss.split("/")[1];
  }

  getDispplaySecond(second) {
    return parseInt(second);
  }

  getSelectedOptions(selected) {
    this.selected = selected;
  }

  getUserId(image) {
    const userId = image.split("/")[0];
    return userId;
  }

  onKey(value) {
    this.userList = this.filterUsers;

    this.userList = this.search(value);

  }

  search(value: string) {
    let filter = value.toLowerCase();

    return this.userList.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
  onStackDateChange(event) {
    let date = event.value;
    this.stackDate = this.datePipe.transform(date, "yyyy-MM-dd");
    this.page = 1;
    // this.getStackedChartData(this.stackDate,this.searchStack);
    this.filterTeam()
  }

  stackDatePrevNextAction(prev) {
    this.page = 1;
    if (prev) {
      this.stackDate = new Date(
        new Date(this.stackDate).getFullYear(),
        new Date(this.stackDate).getMonth(),
        new Date(this.stackDate).getDate() - 1
      );
      this.filterTeam()
      // this.getStackedChartData(
      //   this.datePipe.transform(this.stackDate, "yyyy-MM-dd"),this.searchStack
      // );
    } else {
      this.stackDate = new Date(
        new Date(this.stackDate).getFullYear(),
        new Date(this.stackDate).getMonth(),
        new Date(this.stackDate).getDate() + 1
      );
      this.filterTeam()
      // this.getStackedChartData(
      //   this.datePipe.transform(this.stackDate, "yyyy-MM-dd"),this.searchStack
      // );
    }
  }
  onStartDateChange(event) {
    // this.range;
    let date = event.value;
    this.startDate = this.datePipe.transform(date, "yyyy-MM-dd");
    // this.stackDate = this.startDate;
    this.endDate = null;
    if (this.startDate && this.endDate) {
      this.workingHours = null;
      this.appUsageHours = null;

      this.startDate = this.startDate ? this.startDate : this.datePipe.transform(this.startdate, "yyyy-MM-dd")
      this.endDate = this.endDate ? this.endDate : this.datePipe.transform(this.enddate, "yyyy-MM-dd")

      // this.getTotalHour(this.startDate, this.endDate);
      this.getPieChartData(this.startDate, this.endDate);
      // this.getStackedChartData(this.endDate);
    }
  }

  onEndDateChange(event) {
    this.endDate = this.datePipe.transform(event.value, "yyyy-MM-dd");
    if (this.startDate && this.endDate) {
      this.workingHours = null;
      this.appUsageHours = null;

      this.startDate = this.startDate ? this.datePipe.transform(this.startDate, "yyyy-MM-dd") : this.datePipe.transform(this.startdate, "yyyy-MM-dd")
      this.endDate = this.endDate ? this.endDate : this.datePipe.transform(this.enddate, "yyyy-MM-dd")

      // this.getTotalHour(this.startDate, this.endDate);
      this.getPieChartData(this.startDate, this.endDate);
      // this.getStackedChartData(this.endDate);
    }
  }

  onStartDateChange_ss(event) {
    let date = event.value;
    this.startdate_ss = this.datePipe.transform(date, "yyyy-MM-dd");
  }

  onEndDateChange_ss(event) {
    this.enddate_ss = this.datePipe.transform(event.value, "yyyy-MM-dd");
    if (this.startdate_ss && this.enddate_ss) {

      if (this.EmpPaginator) {
        this.EmpPaginator.firstPage()
      }
      this.offset = 0;
      this.currentPage = 1;
      this.getLastScreenshots();
      this.getLastFiftyPhotos();
    }
  }

  getTotalHour(start, end) {
    this.donutChartData = []
    this._isTotalHoursLoading = true
    this._projectService.getWorkinHourV2(start, end, this.selectedEmp).subscribe(
      (user: any) => {
        this.donutChartData = []
        let data;

        let ideal = [];
        let total = [];
        let productive = [];
        let label = ["Idle", "Productive",]
        let display = []
        for (let key in user) {
          ideal.push(user[key].idle);
          productive.push(user[key].productive);
        }
        data = {
          idle_time: this.addTimes(ideal) ? this.addTimes(ideal) : "00:00:00",
          productive_time: this.addTimes(productive)
            ? this.addTimes(productive)
            : "00:00:00",
        };

        this.donutChartData.push(data.idle_time)
        this.donutChartData.push(data.productive_time)

        // this.donutChartData = data;
        this.donutChartLabel = label
        this._isTotalHoursLoading = false
      },
      (err) => {
        this.donutChartData = [];
        this._isTotalHoursLoading = false
        this._projectService.updateDonutChartData({ data: undefined });
      }
    );
  }

  async getPieChartData(start, end) {
    this.isPiechartLoad = true;
    // this._isAppUsageLoading = true;
    if (this.isTourActive()) {
      this._projectService.getAppUsageCount(start, end, this.selectedEmp).subscribe((res) => {
        let data = [];
        let label = [];
        let display = [];
        const chartData = this.getApplicationUsageData(res)
        chartData.forEach((item) => {
          label.push(item.appName);
          data.push(item.usageTime);
          display.push(item);
        })
        this.isPiechartLoad = false;
        this._projectService.updateApplicationUsageData({ data: data, label: label, display: display, start_date: this.datePipe.transform(this.startDate, "yyyy-MM-dd"), end_date: this.datePipe.transform(this.endDate, "yyyy-MM-dd") })
        this.pieChartData = data;
        this.pieChartLabel = label;
        this.pieChartDisplayData = display;
        this._isAppUsageLoading = false
      },
        (err) => {
          this._projectService.updateApplicationUsageData({ data: undefined, label: undefined, display: undefined })
          this.pieChartData = [];
          this._isAppUsageLoading = false;
          this.isPiechartLoad = false;
        }
      );
    } else {
      this.isPiechartLoad = false;
      let data = [];
      let label = [];
      let display = [];
      let ApplicationJson: any = this._tourService.getApplicationUsageJsonData();
      ApplicationJson.forEach((item) => {
        label.push(item.appName);
        data.push(item.usageTime);
        display.push(item);
      })
      this.isPiechartLoad = false;
      console.log(ApplicationJson, "app json")
      this._projectService.updateApplicationUsageData({ data: data, label: label, display: display })
      this.pieChartData = data;
      this.pieChartLabel = label;
      this.pieChartDisplayData = display;
      this._isAppUsageLoading = false
    }
  }



  getApplicationUsageData(appUsage) {
    let data = [];
    for (let key in appUsage) {
      if (key != "" || key) {
        data.push({ appName: key, usageTime: appUsage[key].usageTime != 0 ? appUsage[key].usageTime : '00:00:00', productive_time: appUsage[key].productive_time, idleTime: appUsage[key].idleTime });
      }
    }

    // Your original array of objects

    // Sort the original array in descending order based on usageTime
    data.sort((a, b) => {
      const timeA = this.parseTime(a.usageTime);
      const timeB = this.parseTime(b.usageTime);
      return timeB - timeA;
    });

    // Function to parse the usageTime into seconds


    // Extract the top 10 elements
    const top10Array = data.slice(0, 10);

    // Log the top 10 elements
    return top10Array

  }

  parseTime(timeString) {
    if (timeString != 0) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    } else return "00:00:00"
  }

  getStackedChartData(start, search) {
    this.isData = true;
    this._isStackbarLoading = true
    this.stackedArr = undefined;
    this.stackedChartlabel = [];
    this.stackedChartIds = [];
    if (this.isTourActive()) {
      this._projectService.getStackchartData(start, this.page, search).subscribe(
        async (data: any) => {
          this.isData = true;
          // console.log("data--val",data)
          // console.group(" StackBar chart ");
          // console.table(" API response ", data);
          // console.table(" API response ", data);
          for (let key in data) {
            if (key == "min") {
              const [hours, minutes, second] = data[key].split(":").map(Number);

              this.x_min = this.padZeroes(hours) + "." + this.padZeroes(minutes);
              // this.x_min = data[key];
            } else if (key == "max") {
              if (data[key]) {
                const [hours, minutes, second] = data[key].split(":").map(Number);

                this.x_max = this.padZeroes(hours) + "." + this.padZeroes(minutes);
              }

            } else if (key == "total_users") {
              this.totalStack_user = Number(data[key])
              if (Number(this.totalStack_user) < 10) {
                this.startPage = 1;
                this.endPage = Number(this.totalStack_user);
              } else {
                this.startPage = 1;
                this.endPage = 10;
              }
            }
            else {
            }
          }
          if (Number(this.x_min) > Number(this.x_max)) {
            this.x_max = 23.59;
          } else {
            if (Number(this.x_max) - Number(this.x_min) < 8.0) {

              if (Number(this.x_min) + 8.0 > 23.59) {
                this.x_max = 23.59;
              } else {
                this.x_max = Number(this.x_min) + 8.0
                this.x_max = Math.ceil(this.x_max)
              }
            } else {
              this.x_max = Math.ceil(this.x_max)
            }
          }

          delete data.max;
          delete data.min;
          if (this.totalStack_user) {
            if (Number(this.totalStack_user) < 10) {
              this.totalPage = 1;
            } else {
              const numberToRound = Number(this.totalStack_user) / 10;
              this.totalPage = Math.ceil(numberToRound);
            }

            delete data.total_users;
          }
          if (this.totalPage != this.page) {
            this.isData = true;
          } else {
            this.isData = false;
          }
          for (const date in data) {
            if (this.displayUserName(date) != '') {
              data[date]["name"] = this.displayUserName(date);
            }
          }
          let sortedKey = Object.keys(data).sort((a, b) => data[a].name.localeCompare(data[b].name))

          const newDataVal = {};
          sortedKey.forEach(key => {
            this.stackedChartIds.push(key)
            newDataVal[this.displayUserName(key)] = data[key];
          });
          this.makeStackBarChartData(newDataVal)
          this._isStackbarLoading = false
        },
        (err) => {
          if (err.status == 400) {
            if (this.page != 1) {
              this.isData = false;
            }
            this.totalPage = 0;
            this.totalStack_user = 0;
            this.startPage = 0;
            this.endPage = 0;
            this.makeStackBarChartData([])
            this._isStackbarLoading = false
          }
        }
      );
    } else {
      let newDataVal = this._tourService.getTimelineJsonData();
      this.makeStackBarChartData(newDataVal)
      this._isStackbarLoading = false
    }
  }

  WeekPrevNextAction(prev) {
    if (prev) {
      if (this.page != 1) {

        this.startPage = this.startPage - 10;
        // console.log("total---",this.totalPage,this.page)
        if (this.page == this.totalPage) {
          this.endPage = Math.floor(this.endPage / 10) * 10;
          // console.log(this.endPage ,"total---")
        } else {
          this.endPage = this.endPage - 10;
        }
        this.page = this.page - 1;

        this.getStackedChartData(
          this.datePipe.transform(this.stackDate, "yyyy-MM-dd"), this.searchStack)
      }
    } else {
      if (this.isData) {
        this.page = this.page + 1;
        this.startPage = this.startPage + 10;
        if (this.page == this.totalPage) {
          this.endPage = Number(this.totalStack_user);
        } else {
          this.endPage = this.endPage + 10;
        }
        this.getStackedChartData(
          this.datePipe.transform(this.stackDate, "yyyy-MM-dd"), this.searchStack
        )
      }
    }
  }

  padZeroes(value: number) {
    return value.toString().padStart(2, '0');
  }

  async addMissingUsersForTimeline(data, users) {
    users.forEach((user: any) => {
      let found = false;
      for (const userId in data) {
        if (userId == user.id) found = true
      }

      if (!found) {
        data[user.id] = { sessions: [] }
      }
    })
    return data
  }

  makeStackBarChartData(data) {
    // console.log("THIS IS THE FINAL STACK BAR CHART", data)
    try {
      if (data) {
        this.tempDataTooltip = [];
        this.newSessionTimeline = [];
        const newDateLogs = {};
        const dataset = new Map();
        for (const date in data) { // loop user ids
          const sessions = data[date].sessions; // store sessions for current date
          const chartData = this._sharedService.gettingTheCorrectSession(sessions, 'timeline');
          this.newSessionTimeline.push(sessions)
          this.tempDataTooltip.push(chartData.sessions);
          this.stackedChartlabel.push(date);
          if (sessions) {
            /** @note processing logs for one date's session. */
            let sessionLogs = [];
            if (!sessions.length) {
              newDateLogs[date] = sessionLogs;
              continue;
            }
            for (let it = 0; it < sessions.length; it++) {
              let item = sessions[it];
              let idlebool = item.idle != "00:00:00";
              let usagebool = item.usage != "00:00:00";
              let data = this._sharedService.formateTimeInDecimal(usagebool ? item.usage : item.idle);
              const itemout = moment(item.in).format('YYYY-MM-DD') === moment(item.out).format('YYYY-MM-DD') ? moment(item.out).format('HH:mm:ss') : '23:59:59';

              if (it === 0) { // @note always consider first log to be either, never both.
                sessionLogs.push({
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout, // moment(item.out).format('HH:mm:ss'),
                  label: idlebool ? 'idle' : 'usage',
                });
              }
              let prevSessionLog = { ...sessionLogs[sessionLogs.length - 1] };

              /** @note for previous idle log and current idle log */
              if (it > 0 && !usagebool && idlebool && prevSessionLog['label'] === 'idle' && prevSessionLog['usage'] === "00:00:00") {
                const difference = this._sharedService.getTimeDifference(prevSessionLog.out, moment(item.in).format('HH:mm:ss'))
                /** @note if the difference is more than 1 minute seperate the logs */
                if (difference > 0.02) {
                  sessionLogs.push({ // will always be idle log
                    ...item,
                    data,
                    date: moment(item.in).format('YYYY-MM-DD'),
                    in: moment(item.in).format('HH:mm:ss'),
                    out: itemout, //moment(item.out).format('HH:mm:ss'),
                    label: 'idle',
                  });
                } else {
                  prevSessionLog.out = itemout, // moment(item.out).format('HH:mm:ss');
                    prevSessionLog.data += data;
                  prevSessionLog.appName = item.appName;
                  prevSessionLog.windowName = item.windowName;
                  sessionLogs[sessionLogs.length - 1] = prevSessionLog;
                }
              }

              // @note if previous log is usage and current is idle log
              if (it > 0 && !usagebool && idlebool && prevSessionLog['label'] === 'usage') {
                sessionLogs.push({ // will always be idle log
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout,
                  label: 'idle',
                });
              }

              /** @note for previous usage log and current usage log */
              if (it > 0 && usagebool && !idlebool && prevSessionLog['label'] === 'usage' && prevSessionLog['idle'] === "00:00:00") {
                const difference = this._sharedService.getTimeDifference(prevSessionLog.out, moment(item.in).format('HH:mm:ss'))
                /** @note if the difference is more than 1 minute seperate the logs */
                if (difference > 0.02) {
                  sessionLogs.push({ // will always be usage log
                    ...item,
                    data,
                    date: moment(item.in).format('YYYY-MM-DD'),
                    in: moment(item.in).format('HH:mm:ss'),
                    out: itemout, // moment(item.out).format('HH:mm:ss'),
                    label: 'usage',
                  });
                } else {
                  prevSessionLog.out = moment(item.out).format('HH:mm:ss');
                  prevSessionLog.data += data;
                  prevSessionLog.appName = item.appName;
                  prevSessionLog.windowName = item.windowName;
                  sessionLogs[sessionLogs.length - 1] = prevSessionLog;
                }
              }

              // @note if previous log is idle and current is usage log
              if (it > 0 && usagebool && !idlebool && prevSessionLog['label'] === 'idle') {
                sessionLogs.push({ // will always be idle log
                  ...item,
                  data,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: moment(item.in).format('HH:mm:ss'),
                  out: itemout, // moment(item.out).format('HH:mm:ss'),
                  label: 'usage',
                });
              }

              // @note for previous usage log and current one being usage as well as with idle, add seperate logs for both.
              if (it > 0 && usagebool && idlebool && prevSessionLog['label'] === 'usage') {
                /** @note add an idle log at the end for time difference. */
                const newOutInTime = this._sharedService.getNewTimeAfterDifference(item.idle, itemout);
                prevSessionLog.out = newOutInTime;
                prevSessionLog.data += data; // will always be usage log
                prevSessionLog.appName = item.appName;
                prevSessionLog.windowName = item.windowName;
                sessionLogs[sessionLogs.length - 1] = prevSessionLog;

                /** @fixme required to fix since causes unncessary padding. */
                // sessionLogs.push({ // will always be idle log, no need for non-usage log in the middle
                //   ...item,
                //   data,
                //   date: moment(item.in).format('YYYY-MM-DD'),
                //   in: newOutInTime,
                //   out: itemout, // moment(item.out).format('HH:mm:ss'),
                //   label: 'idle',
                // });
              }

              // @note for previous idle log and current one being usage as well as with idle, add seperate logs for both.
              if (it > 0 && usagebool && idlebool && prevSessionLog['label'] === 'idle') {
                const newOutInTime = this._sharedService.getNewTimeAfterDifference(item.usage, itemout);
                prevSessionLog.out = newOutInTime;
                prevSessionLog.data += data; // will always be idle log
                prevSessionLog.appName = item.appName;
                prevSessionLog.windowName = item.windowName;
                sessionLogs[sessionLogs.length - 1] = prevSessionLog;

                sessionLogs.push({ // will always be usage log, no need for non-usage log in the middle
                  ...item,
                  data,
                  appName: item.appName,
                  windowName: item.windowName,
                  date: moment(item.in).format('YYYY-MM-DD'),
                  in: newOutInTime,
                  out: moment(item.out).format('HH:mm:ss'),
                  label: 'usage',
                });
              }
            }
            newDateLogs[date] = sessionLogs;
          }
        }

        Object.values(newDateLogs).forEach((dateSess: any, dateSessIn) => {
          dateSess.forEach((sessLog, sessLogIn) => {
            dataset.set(`${sessLogIn}-nonusage`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'nonusage',
              backgroundColor: 'rgba(255,255,255,0)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });

            dataset.set(`${sessLogIn}-idle`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'idle',
              backgroundColor: 'rgba(197,199,235,1)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });

            dataset.set(`${sessLogIn}-usage`, {
              data: Array(Object.keys(newDateLogs).length).fill([0, 0]),
              label: 'usage',
              backgroundColor: 'rgba(101,105,213,1)',
              borderColor: 'rgba(255,255,255,0)',
              borderWidth: 0,
              barThickness: 35,
            });
          });

        });
        Object.values(newDateLogs).forEach((dateSess: any, dateSessIn) => { // 7 days
          dateSess.forEach((sessLog, sessLogIn) => { // 11 sessions - 3 log types
            let existingSessLog = dataset.get(`${sessLogIn}-${sessLog.label}`); /** @debug which index should be used? */
            if (existingSessLog) {
              existingSessLog.data[dateSessIn] = sessLog.in ? [this._sharedService.formateTimeInDecimal(sessLog.in), this._sharedService.formateTimeInDecimal(sessLog.out)] : [0, 0];
              dataset.set(`${sessLogIn}-${sessLog.label}`, {
                ...existingSessLog,
                ...sessLog,
                label: sessLog.label,
                data: existingSessLog.data,
              });
            }
          });
        });

        this.stackedChartData = Array.from(dataset.values());
        console.log(this.stackedChartData, "stackchart----data")
      } else {
        // No Records Found for selected Dates
      }
    } catch (error) {
      // console.error(" ERROR in making stackbar chart data ", error);
    }
  }

  selectEmployee(value) {
    this.appUsageHours = null;
    this.workingHours = null;
    this.selectedEmp = value.toString();
    this.startDate = this.startDate
      ? this.startDate
      : this.datePipe.transform(this.startdate, "yyyy-MM-dd");
    this.endDate = this.endDate
      ? this.endDate
      : this.datePipe.transform(this.enddate, "yyyy-MM-dd");
    this.getTotalHour(this.startDate, this.endDate);
    this.getPieChartData(this.startDate, this.endDate);
    this.getLastScreenshots();
  }

  addTimes(timeArr) {
    const totalMilliseconds = timeArr
      .map((timeStr) => {
        const timeParts = timeStr.split(":");

        return (
          parseInt(timeParts[0]) * 3600000 +
          parseInt(timeParts[1]) * 60000 +
          parseInt(timeParts[2]) * 1000
        );
      })
      .reduce((acc, curr) => acc + curr, 0);

    const hours = Math.floor(totalMilliseconds / 3600000);
    const minutes = Math.floor((totalMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((totalMilliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  getCorrectData(photosData: any) {
    return photosData.filter((ele) => {
      let user_id = Object.values(ele)[0]["user_id"];
      Object.values(ele)[0]['captured_time'] = Object.values(ele)[0]['captured_at'];
      let isShowTime = this.checkTimeForImage(Object.values(ele)[0]['captured_at']);
      Object.values(ele)[0]['isShowTime'] = isShowTime;
      Object.values(ele)[0]['captured_at'] = this.customtimeAgoPipe.transform(Object.values(ele)[0]['captured_at']);
      return user_id != "0";
    });
  }

  getUserfromUserList(userInfo) {
    // console.log("userInfo :: ==>" , userInfo);
    let selectedUser = this.userList.filter((ele: any) => {
      return ele.id == parseInt(userInfo);
    });
    // console.log("selected user:" , selectedUser);
    return selectedUser[0];
  }

  getUserName(userId) {
    let selectedUser = this.userList.filter((ele: any) => {
      if (ele.id == parseInt(userId)) {
        return ele.name;
      }
    });
    return selectedUser[0].name;
  }

  convertTimeIntoFloat(time) {
    const [hours, minutes] = time.split(":").map(Number);

    const floatingValue = parseFloat(
      `${hours == "00" ? "24" : hours}.${minutes}`
    ).toFixed(2);
    return floatingValue;
  }

  timeToNumber(timeString) {
    // Your time value
    // var timeString = "08:30:00";

    // Split the time string into hours, minutes, and seconds
    var timeParts = timeString.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Calculate the decimal representation
    var decimalValue = hours + minutes / 60;

    // Round the decimal value to two decimal places
    decimalValue = Math.round(decimalValue * 100) / 100;
    return decimalValue;
  }

  getDashboardSection(section) {
    if (section == 'timeline') {
      this.timelineSection = true;
      this.screenshotSection = false;
      this.timelineBackground = 'timeline-section-btn-bg-color';
      this.screenshotBackground = '';
    } else if (section == 'screenshot') {
      this.timelineSection = false;
      this.screenshotSection = true;
      this.timelineBackground = '';
      this.screenshotBackground = 'timeline-section-btn-bg-color';
    }
  }

  onPageChange(event) {
    console.log("page change---", this.pageSize, this.currentPage)
    if (event.pageSize && event.pageSize > 0) {
      this.pageSize = event.pageSize;
    } else {
      this.pageSize = this.EmpPaginator["_pageSize"];
    }
    this.offset = this.EmpPaginator["_pageIndex"] + 1;
    this.currentPage = this.EmpPaginator["_pageIndex"] + 1;
    this.getLastScreenshots();
  }

  getTeam() {
    if (this.isTourActive()) {
      this._projectService.getTeamList().subscribe(
        (team: any) => {
          this.teamList = team;
        },
        (err) => {
        }
      );
    }
  }

  getTotalOnlineOflineData() {
    if (this.isTourActive()) {
      this._projectService.getOnlineOfflinePercentage().subscribe((data: any) => {
        // const total = Number(this.onlineUsers) + Number(this.offlineUsers);
        // let online = 100 -((100*Number(this.onlineUsers)/Number(data.online)));
        // let newOnline = ((Number(this.onlineUsers)-Number(data.online))/Number(data.online))*100;
        // let offline = 100 -((100*Number(this.offlineUsers)/Number(data.offline)));
        // let newoffline = ((Number(this.offlineUsers)-Number(data.offline))/100);
        // console.log(online,offline,newOnline,newoffline,"online---")

        // this.onlinePercentage = online % 1 === 0 ? online.toFixed(0) : online.toFixed(2);
        // this.offlinePercentage = offline % 1 === 0 ? offline.toFixed(0) : offline.toFixed(2);
        let online = 0;
        let offline = 0;

        if (Number(this.onlineUsers) >= Number(data.online)) {
          if (Number(data.online) != 0) {
            online = ((Number(this.onlineUsers) - Number(data.online)) / Number(data.online)) * 100
          } else {
            online = (Number(this.onlineUsers)) / 100
          }
          this.onlinePercentageIcon = "north"
        } else {
          online = ((Number(data.online) - Number(this.onlineUsers)) / Number(data.online)) * 100
          this.onlinePercentageIcon = "south"
        }
        if (Number(this.offlineUsers) >= Number(data.offline)) {
          if (Number(data.offline) != 0) {

            offline = ((Number(this.offlineUsers) - Number(data.offline)) / Number(data.offline)) * 100
          } else {
            offline = (Number(this.offlineUsers)) / 100
          }
          this.offlinePercentageIcon = "north"
        } else {
          offline = ((Number(data.offline) - Number(this.offlineUsers)) / Number(data.offline)) * 100
          this.offlinePercentageIcon = "south"
        }
        this.onlinePercentage = online.toFixed(0);
        this.offlinePercentage = offline.toFixed(0);
      }, (err) => { });
    }
  }

  searchTimelineData(value: any) {
    this.searchStack = value;
    this.page = 1;
    this.filterTeam()
    // this.getStackedChartData(this.datePipe.transform(this.stackDate, "yyyy-MM-dd"), this.searchStack)
  }

  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }

  teamSelected(value: any) {
    if (this.selectedTeam == this.currentTeam) {
      this.selectedTeam = '';
      this.filterTeamStackArr = [];
      this.page = 1;
      this.filterTeam()
      return
    }
    this.currentTeam = this.selectedTeam;
    let getAllTeamDevIds = this.getTeamsAllIds(value, "team_developer");
    let getTeamLeadsIds = this.getTeamsAllIds(value, "team_lead");
    let getTeamManagerIds = this.getTeamsAllIds(value, "team_manager");
    this.filterTeamStackArr = Array.from(new Set([...getAllTeamDevIds, ...getTeamLeadsIds, ...getTeamManagerIds]));
    this.page = 1;
    this.filterTeam()
  }
  teamSelectionChange() {
  }
  searchUsersByTeams() { }

  getTeamsAllIds(data: any, type: any) {
    let teamDataString = data.meta[type];
    return teamDataString.split("~").filter((element) => {
      if (parseInt(element)) return parseInt(element)
    })
  }

  filterTeam() {
    this.isData = true;
    this._isStackbarLoading = true
    this.stackedArr = undefined;
    this.stackedChartlabel = [];
    this.stackedChartIds = [];
    if (this.isTourActive()) {
      let start = this.stackDate ? this.datePipe.transform(this.stackDate, "yyyy-MM-dd") : '';
      this._projectService.getFilterStackchartDataByTeam(start, this.page, this.searchStack, this.filterTeamStackArr.join(',')).subscribe(
        async (data: any) => {
          this.isData = true;
          // console.log("data--val",data)
          // console.group(" StackBar chart ");
          // console.table(" API response ", data);
          // console.table(" API response ", data);
          for (let key in data) {
            if (key == "min") {
              const [hours, minutes, second] = data[key].split(":").map(Number);

              this.x_min = this.padZeroes(hours) + "." + this.padZeroes(minutes);
              // this.x_min = data[key];
            } else if (key == "max") {
              if (data[key]) {
                const [hours, minutes, second] = data[key].split(":").map(Number);

                this.x_max = this.padZeroes(hours) + "." + this.padZeroes(minutes);
              }

            } else if (key == "total_users") {
              this.totalStack_user = Number(data[key])
              if (Number(this.totalStack_user) < 10) {
                this.startPage = 1;
                this.endPage = Number(this.totalStack_user);
              } else {
                this.startPage = 1;
                this.endPage = 10;
              }
            }
            else {
            }
          }
          if (Number(this.x_min) > Number(this.x_max)) {
            this.x_max = 23.59;
          } else {
            if (Number(this.x_max) - Number(this.x_min) < 8.0) {

              if (Number(this.x_min) + 8.0 > 23.59) {
                this.x_max = 23.59;
              } else {
                this.x_max = Number(this.x_min) + 8.0
                this.x_max = Math.ceil(this.x_max)
              }
            } else {
              this.x_max = Math.ceil(this.x_max)
            }
          }

          delete data.max;
          delete data.min;
          if (this.totalStack_user) {
            if (Number(this.totalStack_user) < 10) {
              this.totalPage = 1;
            } else {
              const numberToRound = Number(this.totalStack_user) / 10;
              this.totalPage = Math.ceil(numberToRound);
            }

            delete data.total_users;
          }
          if (this.totalPage != this.page) {
            this.isData = true;
          } else {
            this.isData = false;
          }
          for (const date in data) {
            if (this.displayUserName(date) != '') {
              data[date]["name"] = this.displayUserName(date);
            }
          }
          let sortedKey = Object.keys(data).sort((a, b) => data[a].name.localeCompare(data[b].name))

          const newDataVal = {};
          sortedKey.forEach(key => {
            this.stackedChartIds.push(key)
            const trimmedName = this.displayUserName(key).length <= 20 ? this.displayUserName(key) : this.displayUserName(key).slice(0, 20) + (this.displayUserName(key).length > 20 ? '...' : '');
            newDataVal[trimmedName] = data[key];
          });
          this.makeStackBarChartData(newDataVal)
          this._isStackbarLoading = false
        },
        (err) => {
          if (err.status == 400) {
            if (this.page != 1) {
              this.isData = false;
            }
            this.totalPage = 0;
            this.totalStack_user = 0;
            this.startPage = 0;
            this.endPage = 0;
            this.makeStackBarChartData([])
            this._isStackbarLoading = false
          }
        }
      );
    } else {
      let newDataVal = this._tourService.getTimelineJsonData();
      this.makeStackBarChartData(newDataVal)
      this._isStackbarLoading = false
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isProgressBarClicked = clickedElement.id === 'progress_bar';
    if (!isProgressBarClicked) {
      if (document.getElementById('TooltipId')) {
        var existingTooltip = document.getElementById('TooltipId');
        existingTooltip.parentNode.removeChild(existingTooltip);
      }
    }
  }

  trimName(name) {
    const trimmedName = name.length <= 20 ? name : name.slice(0, 20) + (name.length > 20 ? '...' : '');
    return trimmedName;
  }

  change(event: any) {
    if (this.selectedDateAndTime && this.selectedDateAndTime.startDate && this.selectedDateAndTime.endDate) {

      if (this.EmpPaginator) {
        this.EmpPaginator.firstPage()
      }
      this.offset = 0;
      this.currentPage = 1;
      this.getLastScreenshots();
      this.getLastFiftyPhotos();
    }
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  checkTimeForImage(time: string) {
    const inputTime = new Date(time);
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - inputTime.getTime();
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);
    return timeDifferenceInMinutes > 10;
  }

}


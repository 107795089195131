import { Component, Input, Renderer2 } from '@angular/core';
import * as Chart from 'chart.js';
import { ProjectService } from 'src/app/Services/project.service';
import { TourConfigService } from 'src/app/Services/tour-config.service';

@Component({
  selector: 'application-usage',
  templateUrl: './application-usage-chart.component.html',
  styleUrls: ['./application-usage-chart.component.css']
})
export class ApplicationUsageChartComponent {
  chart: any;
  @Input("data") data:any;
  @Input("labels") labels:any;
  @Input("chartdata") chartdata:any = [];
  @Input("chartdata") displayData:any = [];

  _chart: Chart;
  isdata = true;
  isData = true;
  maxTime = '0';
  constructor(private renderer: Renderer2,private _projectService:ProjectService,private _tourService:TourConfigService) {
    this._projectService.ApplicationUSage.subscribe((value:any) => {
      
      console.log(value,"value----")
      this.chartdata = value;
      this.displayData = value;
      if(value.display){
        this.maxTime = this.getTotalTime(value.data)
        this.updateChart();
        // this.maxTime = this.timeToDecimalHours(max);
      }else{
        this.maxTime = '00:00';
        this.updateChart();
      }
    });
    }

  ngOnInit() { }

  ngAfterViewInit() {
    this.createPieChart();
  }
 
  createPieChart() {
    const container:any = document.getElementById('donutChartContainer');

    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(
      document.getElementById('donutChartNewContainer'),
      canvas
    );
    const ctx = canvas.getContext('2d');
    canvas.width = 30; 
    canvas.height = 30; 

    this.chart = new Chart(ctx, {
      type: 'doughnut',
    
      data: {
        labels:["No used application"],
        datasets: [
          {
            data: [100],
            backgroundColor: ['#2143BC', '#2172BC', '#BC6B21', '#F9C80E', '#A621BC', '#21BC68', '#BC214F', '#6521BC', '#000000','#E84646'],
          },
        ],
      },
      options: {
        cutoutPercentage: 75,
        elements: {
          
          arc: {
              borderWidth: 8,
          },
      },
    
        responsive: true,
         legend:{
          display:false
         },
        tooltips:{
          // label: (ttItem,data) => (this.chartdata.data ? `${data.labels[ttItem.index]}: ${data.datasets[ttItem.datasetIndex].data[ttItem.index]}` : 'No used application')

          callbacks: {
            label: (ttItem,data) => (this.chartdata.data ? `${data.labels[ttItem.index]}: ${this.convertIntoTime(ttItem.index)}` : 'No used application')
          },
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: true,
          // enabled:false,
         
        },
       
        },
    
    });
    // window.addEventListener('resize', () => {
    //   canvas.width = container.offsetWidth;
    //   canvas.height = container.offsetHeight;
    //   this.chart.update();
    // });
  }

   customChartToolTip(tooltipModel: any) {
  }
  timeToDecimalHours(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return (hours + minutes / 60 + seconds / 3600).toFixed(2);
  }
  updateChart() {
    if (this.chartdata && this.chartdata.data !== undefined) {
      this.isdata = true;
      this.chart.data.labels = this.chartdata.label;
      this.chart.data.datasets[0].data = this.convertToMilliseconds(this.chartdata.data);
      this.chart.update();
    } else {
      this.isdata = false;
      this.chart.data.labels = ["No used application"];
      this.chart.data.datasets[0].data = [100];
      this.chart.update();
    }
  }
  

  convertToMilliseconds(timeArr) {
    let timeData = [];
    let totalHours = 0;
    for (let i = 0; i <= timeArr.length - 1; i++) {
     

      const milliseconds = this.timeToMilliseconds(timeArr[i]);
      const hours = this.millisecondsToHours(milliseconds);
      timeData.push(Number(hours.toFixed(2)));
      totalHours += hours;
      // timeData.push((hours * 3600 + minutes * 60 + seconds) * 1000);
      // timeData.push(hours);

    }
    // this.totalHours.emit(totalHours.toFixed(2).replace(".00", ""));
    // Calculate milliseconds
    return timeData;
  }

  convertIntoTime(index){
    const [hours, minutes, seconds] = this.chartdata.data[index].split(":").map(Number);
    // return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}`;

    return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}` + "h";
  }
  timeToMilliseconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  }

  // Function to convert milliseconds to hours
  millisecondsToHours(milliseconds) {
    const hours = milliseconds / 3600000;
    return hours;
  }
  getTotalTime(timeArray) {
    let totalHours = 0;
    let totalminute = 0;
    for(let i=0 ;i<= timeArray.length-1;i++){
      const [hours, minutes, seconds] = timeArray[i].split(':').map(Number);
      totalHours = totalHours + hours;
      totalminute = totalminute + minutes;
    }
    let division = Math.floor(totalminute / 60);
    let modulo = totalminute % 60;
    totalHours = totalHours + division;
    return totalHours + ":" + modulo;
    // const totalSeconds = timeArray.reduce((total, time) => {
    //   const [hours, minutes, seconds] = time.split(':').map(Number);
    //   return total + hours * 3600 + minutes * 60 + seconds;
    // }, 0);

    // const hours = Math.floor(totalSeconds / 3600);
    // const minutes = Math.floor((totalSeconds % 3600) / 60);
    // const remainingSeconds = totalSeconds % 60;

    // return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}`;
  }
  padZeroes(value: number) {
    return value.toString().padStart(2, '0');
  }
  findMaxTime(timeArray) {
    if (timeArray.length === 0) {
      return "00:00:00"; 
    }
  
    let maxTime = "00:00:00"; 
  
    for (const timeString of timeArray) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  
      const [maxHours, maxMinutes, maxSeconds] = maxTime.split(":").map(Number);
      const maxTotalSeconds = maxHours * 3600 + maxMinutes * 60 + maxSeconds;
  
      if (totalSeconds > maxTotalSeconds) {
        maxTime = timeString;
      }
    }
  
    return maxTime;
  }
}

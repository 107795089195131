import { Component, HostListener, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "./Services/sharedService";
import { ProjectService } from "./Services/project.service";
import { TourService } from 'ngx-ui-tour-md-menu'
import { TourConfigService } from "./Services/tour-config.service";
import { WelcomeComponent } from "./welcome/welcome.component";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
// import { config } from "./config";
import Feedback from "@betahuhn/feedback-js";
import * as platform from 'platform';
import html2canvas from 'html2canvas';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from "./config";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {

  isLoggedIn = JSON.parse(localStorage.getItem("isLogedIn"));
  token = localStorage.getItem('token');
  title = "SuperSee";
  sideMenu = true;
  sideMenuMode = 'side';
  isAdmin = false;
  isTour = true;
  quotaStatus = 'active';
  isShowRenewPlan = true;

  // Initialize FeedbackJS with options
  feedback = new Feedback({
    events: true,
    id: 'feedback',
    primary: '#5459D1',
    // place the feedback button on the bottom left
    position: 'left',
    // change text of the feedback button
    btnTitle: 'Provide Feedback'
  });
  screenshotImage: any;

  constructor(private router: Router, private _sharedServices: SharedService, private _projectService: ProjectService, public tourService: TourService, private _tourConfig: TourConfigService, public dialog: MatDialog, private http: HttpClient, private renderer: Renderer2
  ) {
    localStorage.setItem('isTour', 'false')
    this.token = localStorage.getItem('token');
    if (this.token) {
      this.getUSer();
    }
    this._sharedServices.isLoggedIn().subscribe((result) => {
      this.token = localStorage.getItem('token');
      this.isLoggedIn = result;
      if(this.isLoggedIn){
        this.feedback.renderButton();
      }else{
        const element = document.getElementById('feedback-btn');
        if(element){
          this.renderer.setStyle(element, 'display', 'none');
        }
      }
    })
    this._projectService.isAdminLogin.subscribe(data => {
      if (this.token) {
        this.getUSer();
      }
    })
    // _tourConfig.getTourData();
    // tourService.start()
  }
  tourClose() {
    localStorage.setItem('isTour', 'false')
    this._projectService.updateDashboardTourData({ data: 'true' })
    this.tourService.end()
    this._projectService.updateTour().subscribe((res: any) => {
      localStorage.setItem('isTour', 'false')
    }, err => { })
  }
  tourEnd() {
    localStorage.setItem('isTour', 'false')
    this._projectService.updateDashboardTourData({ data: 'true' })
    this.tourService.end()
    this._projectService.updateTour().subscribe((res: any) => {
      localStorage.setItem('isTour', 'false')
    }, err => { })
  }

  onDrawerToggle(isOpened: boolean) {
    console.log('isOpened', isOpened);
    let element = document.getElementById('feedback-btn');
    if(!isOpened){
      if(element) this.renderer.setStyle(element, 'display', 'none');
    }else{
      if(element) this.renderer.setStyle(element, 'display', 'flex');
    }
  }

  previousAction(event) {
    // if(event.anchorId == 'addNewEmployee'){
    //   this._sharedServices.dashboardScrollerSubject.next('ss_section');
    // }
    this.tourService.prev()
  }
  ngOnInit() {
    let tour = localStorage.getItem('isTour');
    this._sharedServices.toggleMenu().subscribe((result: any) => {
      if (window.screen.width <= 1200) {
        if (tour == 'false') {
          this.sideMenu = !this.sideMenu;
          this.sideMenuMode = 'over';
        }
      } else {
        this.sideMenu = result;
        this.sideMenuMode = 'side';
      }
    });
    if (window.screen.width <= 1200 && tour == 'false') {
      this.sideMenuMode = 'over';
    }
    else if (window.screen.width >= 1200) {
      this.sideMenuMode = 'side';
    }
    if (localStorage.getItem('selectedData')) {
      localStorage.removeItem('selectedData');
    }

    // this.feedback.renderButton();

    window.addEventListener('feedback-submit', (event) => {
      this.handleFeedbackSubmit((event as CustomEvent).detail);
    });

  }
  checkToken() {
    this.token = localStorage.getItem('token');

    if (this.token) {
      return true;
    } else {
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let element = document.getElementById('feedback-btn');
    // this.innerWidth = window.innerWidth;
    if (window.screen.width <= 1200) {
      this.sideMenu = false;
      this.sideMenuMode = 'over';
      if(element) this.renderer.setStyle(element, 'display', 'none');
    } else if (window.screen.width > 1200) {
      this.sideMenu = true;
      this.sideMenuMode = 'side';
      if(element) this.renderer.setStyle(element, 'display', 'flex');
    }
  }

  checkIsActive(currentRoute) {
    if (currentRoute == this.router.url || (currentRoute == '/dashboard' && this.router.url == '/')) {
      return 'active-nav';
    } else if (currentRoute == '/employees' && (this.router.url.includes('/user-details') == true)) {
      return 'active-nav';
    } else if (currentRoute == '/user-report' && (this.router.url.includes('/user-report') == true)) {
      return 'active-nav';
    }
    else if (currentRoute == '/employee/setting' && (this.router.url.includes('/employee/setting') == true)) {
      return 'active-nav';
    }
  }
  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkSideMenu() {
    if (window.screen.width <= 991) {
      this.sideMenu = false;
    }
  }

  getUSer() {
    let roleArr = [];
    this._projectService.getUser().subscribe((data: any) => {
      localStorage.setItem('user', JSON.stringify(data))
      let isExpired = this._sharedServices.checkPlanExpired(data)
      if (isExpired) {
        return this.router.navigateByUrl('/plan-expired');
      }
      console.log(data, "CHECK USERS PLANS IN APP COMPONENT")
      roleArr = data.role;
      this.quotaStatus = data.meta.quota_status;
      let checkDayDiff = this.expiringDate(data.meta.expires_at, data.meta.today_date);
      // for isShowRenewPlan
      // check if quota_remaining is not false and quota_status is active      
      // if (data.meta.quota_remaining != 'false' && data.meta.quota_remaining != false) {
      if (this.quotaStatus == 'active' || this.quotaStatus == 'Active') {
        // if (this.expiringDate(data.meta.expires_at, data.meta.today_date) == true) {
        //   this.isShowRenewPlan = true;
        // } else {
        //   this.isShowRenewPlan = false;
        // }
        console.log("isshowrenewplan", checkDayDiff, data.meta.adv_pay)
        if (checkDayDiff <= data.meta.adv_pay) {
          this.isShowRenewPlan = true;
        }
        else {
          this.isShowRenewPlan = false;
        }
      }
      else if (this.quotaStatus == 'trial') {
        this.isShowRenewPlan = true;
      }
      // }

      let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if (adminRole.length == 0) {
        this.isAdmin = false;
      } else {
        this.isAdmin = true;
      }
      if (data.meta.ss_tooltip_visited == '' || !data.meta.ss_tooltip_visited || data.meta.ss_tooltip_visited == 'false' || data.meta.ss_tooltip_visited == false) {
        if (this.isTour) {
          this.dialog.closeAll();
          const dialogRef = this.openDialog(
            WelcomeComponent
          ).subscribe((response) => { });
          localStorage.setItem('tourStart', 'true')
        }
      } else {
        localStorage.setItem('tourStart', 'false')
      }
      this.isTour = false;
    })
  }

  moveTeamList() {
    if (localStorage.getItem('selectedData')) {
      localStorage.removeItem('selectedData');
    }
    this.router.navigate([`/projects`]);
  }

  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }

  // expiringDate(strDate: any, strToday: any) {
  //   const expirationDate = new Date(strDate);
  //   const timeDifference = expirationDate.getTime() - new Date(strToday).getTime();
  //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //   let isExpirationNear = daysDifference < 15;

  //   // this.daysRemaining = Math.round(daysDifference);
  //   return isExpirationNear;
  // }

  expiringDate(strExpiredDate, strTodayDate) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = expirationDate.getTime() - new Date(strTodayDate).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference + 1;
  }

  async handleFeedbackSubmit(feedbackData) {
    const screenshotElement = document.querySelector('#screenshotArea') as HTMLElement;
    await html2canvas(screenshotElement).then((canvas) => {
      this.screenshotImage = canvas.toDataURL().split(',')[1];
    });
    // let apiUrl = 'https://script.google.com/macros/s/AKfycbxuPS5TBFxJ9TwtOUbMRuzBRd2BM-x43BoI2QgllPYzCxMrh5lpYUYM-4i_0E8890EiGA/exec';
    // let apiUrl = 'https://script.google.com/macros/s/AKfycbywobx2encsGUodrOsaWDABB2q0RLvanBWqDyNV2AupJQMm_6tixlY2JdXmyEHP9YWgyg/exec';
    let apiUrl = "https://script.google.com/macros/s/AKfycbxlwrqNNEU5kRJ_TaUwCivVbRTamUjY83ekloKskN6Fm5eGlFjGn4WPKJQakBkHtKR7eQ/exec"

    let feedbackPayload = {
      version: require('../../package.json').version,
      feedbackType: feedbackData.feedbackType,
      plan: this.quotaStatus,
      feedback: feedbackData.message,
      currentPage: this.router.url,
      osInformation: platform.os.family + ' ' + platform.os.version + ' ( ' + platform.description + ' )',
      currentBrowser: platform.name + ' ' + platform.version,
      currentPageScreenshot: this.screenshotImage
    };

    const headers = new HttpHeaders({
      'Content-Type': 'text/plain;charset=utf-8',
    });

    this.http.post(config.feedBackSheet, feedbackPayload, { headers }).subscribe(
      (response) => {
        console.log('response :: ', response);
      },
      (error) => {
        console.error("ERROR in submit feedback", error);
      }
    );

  }

}


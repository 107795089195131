<!-- <app-header></app-header> -->
<div class="banner">
  <quota-banner></quota-banner>
</div>
<div class="container-fluid " *ngIf="!loading">
  <div class="row">
    <div class="col s12">
      <h1>Dashboard</h1>
    </div>
  </div>

  <!-- Section-1  -->
  <div class="row employee-cards" style="margin: 0" tourAnchor="DashboardInforMativeCard">
    <div class="col s6 m6 xl3 l6">
      <div *ngIf="isloading">
        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
          [theme]="{ 'border-radius': '2', height: '65px', margin: '5px' }"></ngx-skeleton-loader>
      </div>
      <mat-card *ngIf="!isloading" class="total-employee-card" id="total_employee_section">
        <mat-card-content>
          <div class="row">
            <div class="col s12">
              <div class="employee-card-icon-div employee-card-icon-div-bg-total employee-card-icon-div-color-total">
                <span class="material-symbols-outlined"> group </span>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0">
            <div class="col s12">
              <h1 class="employee-card-icon-div-color-total" style="font-family: 'Inter', sans-serif">
                {{ isTourActive() ? onlineUsers + offlineUsers : 100 }}
              </h1>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0; text-align: left;">
            <div class="col s12 m6 l6 xl6">
              <p class="employee-card-icon-div-color-total padding_text">Employees</p>
            </div>
            <!-- <div class="col s12 m6 l6 xl6" style="display: flex; align-items: center; justify-content: end">
              <div class="employee-card-badge employee-card-icon-div-bg-total employee-card-icon-div-color-total">
                34.3% <span class="material-symbols-outlined"> north </span>
              </div>
            </div> -->
          </div>
          <!-- <span class="employee-count"> </span>
          <span class="employee-card-desc">{{ onlineUsers + offlineUsers > 1 ? "Employees" : "Employee" }}</span> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col s6 m6 xl3 l6">
      <div *ngIf="isloading">
        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
          [theme]="{ 'border-radius': '2', height: '65px', margin: '5px' }"></ngx-skeleton-loader>
      </div>
      <mat-card *ngIf="!isloading" class="total-employee-card">
        <mat-card-content>
          <div class="row">
            <div class="col s12">
              <div class="employee-card-icon-div employee-card-icon-div-bg-online employee-card-icon-div-color-online">
                <span class="material-symbols-outlined"> person </span>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0">
            <div class="col s12">
              <h1 class="employee-card-icon-div-color-online" style="font-family: 'Inter', sans-serif">
                {{isTourActive() ? onlineUsers : 100 }}
              </h1>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0; display: flex; align-items: center">
            <div class="col s6">
              <p class="employee-card-icon-div-color-online">Online</p>
            </div>
            <div class="col s6" style="display: flex; align-items: center; justify-content: end">
              <div class="employee-card-badge employee-card-icon-div-bg-online employee-card-icon-div-color-online">
                {{isTourActive() ? onlinePercentage : 100}}% <span class="material-symbols-outlined">
                  {{onlinePercentageIcon}} </span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col s6 m6 xl3 l6">
      <div *ngIf="isloading">
        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
          [theme]="{ 'border-radius': '2', height: '65px', margin: '5px' }"></ngx-skeleton-loader>
      </div>
      <mat-card *ngIf="!isloading" class="total-employee-card">
        <mat-card-content>
          <div class="row">
            <div class="col s12">
              <div
                class="employee-card-icon-div employee-card-icon-div-bg-offline employee-card-icon-div-color-offline">
                <span class="material-symbols-outlined"> person_off </span>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0">
            <div class="col s12">
              <h1 class="employee-card-icon-div-color-offline" style="font-family: 'Inter', sans-serif">
                {{ isTourActive() ? offlineUsers : 100 }}
              </h1>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0; display: flex; align-items: center">
            <div class="col s6">
              <p class="employee-card-icon-div-color-offline">Offline</p>
            </div>
            <div class="col s6" style="display: flex; align-items: center; justify-content: end">
              <div class="employee-card-badge employee-card-icon-div-bg-offline employee-card-icon-div-color-offline">
                {{isTourActive() ? offlinePercentage : 100}}% <span class="material-symbols-outlined">
                  {{offlinePercentageIcon}} </span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col s6 m6 xl3 l6">
      <div *ngIf="isloading">
        <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
          [theme]="{ 'border-radius': '2', height: '65px', margin: '5px' }"></ngx-skeleton-loader>
      </div>
      <mat-card *ngIf="!isloading" class="total-employee-card">
        <mat-card-content>
          <div class="row">
            <div class="col s12">
              <div class="employee-card-icon-div employee-card-icon-div-bg-team employee-card-icon-div-color-team">
                <span class="material-symbols-outlined"> groups </span>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0">
            <div class="col s12">
              <h1 class="employee-card-icon-div-color-team" style="font-family: 'Inter', sans-serif">
                {{isTourActive() ? teamList.length : 100}}
              </h1>
            </div>
          </div>
          <div class="row" style="margin-bottom: 0; text-align: left;">
            <div class="col s6">
              <p class="employee-card-icon-div-color-team padding_text" style="font-family: 'Manrope', sans-serif;">
                Teams
              </p>
            </div>
            <!-- <div class="col s6" style="display: flex; align-items: center; justify-content: end">
              <div class="employee-card-badge employee-card-icon-div-bg-team employee-card-icon-div-color-team">
                34.3% <span class="material-symbols-outlined"> north </span>
              </div>
            </div> -->
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- <mat-form-field appearance="fill" class="col s2 m2 l2 filter-field">
      <mat-label>Enter a date range</mat-label>
      
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [value]="startdate" placeholder="Start date" (dateChange)="onStartDateChange($event)" />
        <input matEndDate [value]="enddate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
        <input matStartDate placeholder="Start date" />
        <input matEndDate placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field> -->

    <!-- <mat-form-field class="col s2 m2 l2 filter-field">
      <mat-label>Select Employees</mat-label>
     
      <mat-select [formControl]="searchMultiCtrl" [multiple]="true" #emp
        (selectionChange)="selectEmployee($event.value)">
        
        <input matInput (keyup)="onKey($event.target.value)" />
        <mat-option *ngFor="let employee of userList" [value]="employee.id">
          <span>{{ employee.name }}</span>
          <span [ngClass]="
              employee.status && employee.status == 'online'
                ? 'online'
                : employee.status == 'ofline' || employee.status == 'offline'
                ? 'offline'
                : 'logout'
            "></span>
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>

  <div class="row" id="most_used_apps_section" tourAnchor="mostUsedAppDashboard">
    <div class="col s12">
      <mat-card class="graph-card">
        <div class="row most-use-row">
          <div class="col s12 m6 most-use-contain">
            <h4>Most Used application</h4>
          </div>
          <div class="col s12 m6" style="display: flex; justify-content: end">
            <mat-form-field class="date-range-picker-form-field">
              <mat-date-range-input [disabled]="!isTourActive()" [rangePicker]="picker">
                <input matStartDate [value]="startdate" placeholder="Start date"
                  (dateChange)="onStartDateChange($event)" />
                <input matEndDate [value]="enddate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
                <!-- <input matStartDate placeholder="Start date" />
                <input matEndDate placeholder="End date" /> -->
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>

        <!-- </mat-card-header> -->
        <mat-card-content class="most-use-card-content">
          <div *ngIf="isPiechartLoad">
            <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
              [theme]="{ 'border-radius': '2', height: '400px', margin: '5px' }"></ngx-skeleton-loader>
          </div>
          <div class="row application-usage-container"
            [ngStyle]="{'display':isPiechartLoad ? 'none':'block','visibility':isPiechartLoad?'hidden':'visible'}">

            <!-- <div  class="col s4" style="width: 682px;
            height: 400px;margin-top: 50px"> -->
            <div class="center-content">

              <div class="col xl3 s12 m12">
                <div style="max-width: 320px;max-height: 320px;margin-left: auto;margin-right: auto;">
                  <application-usage></application-usage>
                </div>
              </div>
              <div class="col xl9 s12  m12">
                <h2></h2>
                <progressbar [isDashboard]="true"></progressbar>
              </div>
            </div>
            <!-- <div class="col s4">

  </div> -->
          </div>
        </mat-card-content>
      </mat-card>
      <div>
      </div>
    </div>
  </div>
  <!-- <div class="row h-100" style="margin-bottom: 10px">
    <div class="col m12">
      <div class="row">
        <div class="col s12 l6">
          <mat-card class="mat-card-main">
            <mat-card-header>
              <mat-card-title> Working Hours {{workingHours ? '('+workingHours+' Hours)' : ''}} </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="_isTotalHoursLoading">
                <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
                    'border-radius': '2',
                    height: '433px',
                    margin: '5px'
                  }"></ngx-skeleton-loader>
              </div>
              <app-pie-chart *ngIf="donutChartData && donutChartData.length && !_isTotalHoursLoading"
                [chartData]="donutChartData" [data]="donutChartData" [labels]="donutChartLabel" [chartType]=" 'pie' "
                [_isLabel]="true" [isDashboard]="true" [colorScheme]="workingHoursColor" (totalHours)="workingHours = $event;"></app-pie-chart>
              <div class="empty_list z-depth-2"
                *ngIf="donutChartData && !donutChartData.length && !_isTotalHoursLoading">
                <img src="assets/images/empty_list.png" alt="empty list image" />
              </div>

            </mat-card-content>
          </mat-card>
        </div>

        <div class="col s15 l6">
          <mat-card class="mat-card-main">
            <mat-card-header>
              <mat-card-title>App Usage {{appUsageHours  ? '('+appUsageHours+' Hours)' : ''}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="_isAppUsageLoading">
                <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
                    'border-radius': '2',
                    height: '433px',
                    margin: '5px'
                  }"></ngx-skeleton-loader>
              </div>
              <app-pie-chart *ngIf="pieChartData && pieChartData.length && !_isAppUsageLoading"
                [chartData]="pieChartData" [data]="pieChartData" [labels]="pieChartLabel"
                [display]="pieChartDisplayData" [chartType]=" 'bar' " [_isLabel]="false" [colorScheme]="appUsageColor"
                [isDashboard]="true" (totalHours)="appUsageHours = $event;"></app-pie-chart>
              <div class="empty_list z-depth-2" *ngIf="pieChartData && !pieChartData.length && !_isAppUsageLoading">
                <img src="assets/images/empty_list.png" alt="empty list image" />
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Section-2  -->

  <!-- <div class="row h-100" style="margin-bottom: 10px; display: none">
    <div class="col m12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Working</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="isloading">
            <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
              [theme]="{ 'border-radius': '2', height: '350px', margin: '5px' }"></ngx-skeleton-loader>
          </div>
          <app-line-chart *ngIf="!isloading"></app-line-chart>
        </mat-card-content>
      </mat-card>
    </div>
  </div> -->

  <!-- <div class="row">
    <div class="col s12 m6 l6">
      <p class="mat-h1 mat-headline" style="margin-bottom: 0px; margin-top: 5px;">Recent Screenshots</p>
    </div>
  </div> -->

  <div class="row">
    <div class="col s12">
      <button mat-stroked-button class="timeline-section-btn {{ timelineBackground }}"
        (click)="getDashboardSection('timeline')">
        Timeline
      </button>
      <button id="screenShotSection" tourAnchor="ScreenShotDashboard" mat-stroked-button
        class="timeline-section-btn {{ screenshotBackground }}" (click)="getDashboardSection('screenshot')">
        Screen Shots
      </button>
    </div>
  </div>

  <div class="row" *ngIf="timelineSection" id="timeline_section" tourAnchor="TimelineDashboard">
    <div class="col s12">
      <mat-card class="fluid-ss timeline-card">
        <div class="row ss-fluid-row">
          <div class="col s12 m12 l3 xl3" style="margin-left: 0px !important;">
            <h4>Employee work timeline</h4>
          </div>
          <div class="col s12 m12 l8 xl8 employee-work-date-col"
            style="margin-left: 0px !important; justify-content: flex-end;">
            <mat-form-field class="timeline-datepicker">
              <mat-label>Select Team</mat-label>
              <mat-select (selectionChange)="teamSelectionChange()" [(ngModel)]="selectedTeam"
                [disabled]="!isTourActive()">
                @for (team of teamsListForFilter; track $index) {
                <mat-option [value]="team.title.rendered"
                  (click)="teamSelected(team)">{{team.title.rendered}}</mat-option>
                } @empty {
                <mat-option>No Teams Available</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div class="search-bar timeline-searchbar">
              <svg style="margin-right: 4px" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
                  fill="#B3B3B3" />
              </svg>

              <input matInput #searchInp placeholder="Search here" (blur)="searchTimelineData(searchInp.value)"
                (keydown.enter)="searchTimelineData(searchInp.value)" [disabled]="!isTourActive()" />
            </div>

            <div class="dateTimeSection">
              <i class="material-icons arrow_icons"
                style="border-top-left-radius: 8px;border-bottom-left-radius: 8px;border-bottom-right-radius: 0px;border-top-right-radius: 0px;"
                (click)="stackDatePrevNextAction(true)">keyboard_arrow_left</i>
              <mat-form-field class="timeline-datepicker" style="border-radius: 0px !important;">
                <input matInput [matDatepicker]="pickerdate" [disabled]="!isTourActive()" [value]="stackDate"
                  style="border-radius: 0px !important;" (dateChange)="onStackDateChange($event)" />
                <mat-datepicker-toggle matIconSuffix [for]="pickerdate"></mat-datepicker-toggle>
                <mat-datepicker #pickerdate></mat-datepicker>
              </mat-form-field>
              <i class="material-icons arrow_icons"
                style="border-top-right-radius: 8px;border-bottom-right-radius: 8px;border-bottom-left-radius: 0px;border-top-left-radius: 0px;"
                (click)="stackDatePrevNextAction(false)">keyboard_arrow_right</i>
            </div>
          </div>
        </div>
        <!-- <mat-card-header>
          <mat-card-title>
            Working / Idle / Productive Hours
            <span>({{ stackDate | date : "MMM dd, yyyy" }})</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content> -->
        <div *ngIf="_isStackbarLoading">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '400px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>

        <app-stacked-chart *ngIf="
            stackedChartData && stackedChartData.length && !_isStackbarLoading
          " [type]="'horizontal'" [data]="stackedChartData" [minValue]="x_min" [maxValue]="x_max"
          [labels]="stackedChartlabel" [tempDataTooltip]="tempDataTooltip" [newSessionData]="newSessionTimeline"
          [id]="stackedChartIds"></app-stacked-chart>



        <div></div>

        <!-- <div> -->
        <div class="empty_list z-depth-2 remove-border" *ngIf="
            !_isStackbarLoading && stackedChartData && !stackedChartData.length
          ">
          <img src="assets/images/empty_list.png" alt="empty list image" />
        </div>
        <!-- </div> -->
        <div class="row">
          <div class="timelin-pagination-div" *ngIf="totalStack_user && totalStack_user != 0">
            <div class="timeline-pagination">
              <p>{{startPage}}-{{endPage}} of {{totalStack_user}}</p>
            </div>
            <button (click)="WeekPrevNextAction(true)">
              <i class="material-icons" style="cursor: pointer" [ariaDisabled]="page == 1">keyboard_arrow_left</i>
              <!-- <span>Previous</span> -->
            </button>
            <button (click)="WeekPrevNextAction(false)">
              <!-- <span>Next</span> -->
              <i class="material-icons" style="cursor: pointer">keyboard_arrow_right</i>
            </button>
          </div>
        </div>

        <!-- </mat-card-content> -->
      </mat-card>
    </div>
  </div>

  <mat-card class="fluid-ss screenshot-dashbord-card" *ngIf="screenshotSection" id="ss_section">
    <div class="row ss-fluid-row most-use-row">
      <div class="col s12 m6 most-use-contain" style="margin: 0;">
        <h4>Screenshots</h4>
      </div>
      <!-- <div class="col s12 m2 timePicker" style="display: flex; justify-content: end">
        <input matInput placeholder="select start time" class="timeInput" [ngxTimepicker]="toggleIcon" [format]="24" readonly>
        <ngx-material-timepicker-toggle [for]="toggleIcon" #fullTime>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px" style="
          fill: #4C4E6499;" ngxMaterialTimepickerToggleIcon>
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"/>
        </svg>
        </ngx-material-timepicker-toggle>
        <ngx-material-timepicker #toggleIcon></ngx-material-timepicker>
      </div>
      <div class="col s12 m2 timePicker" style="display: flex; justify-content: end">
        <input matInput placeholder="select end time" class="timeInput" [ngxTimepicker]="toggleIcons" [format]="24" readonly>
        <ngx-material-timepicker-toggle [for]="toggleIcons" #fullTime>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px" style="
          fill: #4C4E6499;" ngxMaterialTimepickerToggleIcon>
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"/>
        </svg>
        </ngx-material-timepicker-toggle>
        <ngx-material-timepicker #toggleIcons></ngx-material-timepicker>
      </div> -->
      <div class="col s12 m6 timePicker" style="display: flex; justify-content: end">
        <input matInput class="timeInput" ngxDaterangepickerMd [lockStartDate]="false" [locale]="locale"
          [timePicker]="true" [timePicker24Hour]="true" [timePickerSeconds]="true" placeholder="Start date - End date"
          [(ngModel)]="selectedDateAndTime" (change)="change($event)" />
        <mat-icon style="color: gray;
        width: 30px;" class="ngx-daterangepicker-action" (click)="openDatepicker()">today</mat-icon>
        <!-- <mat-form-field class="date-range-picker-form-field">
          <mat-date-range-input [disabled]="!isTourActive()" [rangePicker]="picker_ss">
            <input matStartDate [value]="startdate_ss" placeholder="Start date"
              (dateChange)="onStartDateChange_ss($event)" />
            <input matEndDate [value]="enddate_ss" placeholder="End date" (dateChange)="onEndDateChange_ss($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker_ss"></mat-datepicker-toggle>
          <mat-date-range-picker #picker_ss></mat-date-range-picker>
        </mat-form-field> -->
      </div>

      <!-- <div class="col s12 m6 l6 search-container"> -->
      <!-- <div class="search-bar">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
              fill="#B3B3B3"
            />
          </svg>

          <input matInput #searchInp placeholder=" Search here" />
        </div> -->
      <!-- </div> -->
    </div>
    <div *ngIf="isloading">
      <div class="row">
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" [theme]="{
              'border-radius': '2',
              height: '280px',
              margin: '5px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <!-- <div class="row"> -->
    <!-- <div tourAnchor="ScreenShotDashboard"></div> -->

    <div class="row screenshot-card-main-row" style="margin: 0;">
      <div class="col s6 m6 l6 xl4 screenshot-card" [ngStyle]="{
          display: PhotoList.length ? 'block' : 'none',
          visibility: PhotoList.length ? 'visible' : 'hidden'
        }" *ngFor="let image of PhotoList">
        <ng-container *ngFor="let imageInfo of image | keyvalue">
          <div *ngIf="imageInfo.key != '_links' && imageInfo.value.user_id !== '0'">
            <mat-card class="ss-card">
              <div class="row" style="margin-bottom: 16px">
                <div class="col s12" style="position: relative">
                  <a class="screenshot_box waves-effect waves-light" href="{{ imageInfo.value.large }}"
                    data-fancybox="gallery">
                    <img src="{{ imageInfo.value.large }}" alt="screenshot image" class="responsive-img ss-img" />
                  </a>
                  <div *ngIf="imageInfo.value.app_name" class="absolute-badge">
                    {{ trimName(imageInfo.value.app_name | slice:0:30) }}
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: 0">
                <div class="col s12 m6 l7 xl7 ss-card-profile-col"
                  [routerLink]="['/user-details/', imageInfo.value.user_id ]">
                  <div>
                    <user-profile [user]="getUserfromUserList(imageInfo.value.user_id)" [isName]="false"
                      [userImageClass]="userImageClass"></user-profile>
                  </div>
                  <div style="margin-left: 5px;">
                    <h4>{{ trimName(getUserName(imageInfo.value.user_id)) }}</h4>
                    @if (!imageInfo.value.isShowTime) {
                    <p>
                      {{ imageInfo.value.captured_at }}
                    </p>
                    }
                    @if (imageInfo.value.isShowTime) {
                    <p>
                      {{ imageInfo.value.captured_time }}
                    </p>
                    }
                  </div>
                </div>
                <div class="col s12 m6 l5 xl5 badge-contain">
                  <div class="badge">{{ imageInfo.value.type }}</div>
                  <!-- <button
                      mat-button
                      [matMenuTriggerFor]="menu"
                      style="min-width: auto"
                    >
                      <span class="material-symbols-outlined">
                        more_vert
                      </span>
                    </button> -->
                  <mat-menu #menu="matMenu">
                    <div mat-menu-item class="dropdown-menu-button">
                      <span class="material-symbols-outlined"> delete </span>delete
                    </div>
                  </mat-menu>
                </div>
              </div>
            </mat-card>
            <!-- <div class="card">
              <div class="card-image">
                <div class="chip application-chip">
                  {{ imageInfo.value.app_name }}
                </div>
                <a class="screenshot_box waves-effect waves-light" href="{{ imageInfo.value.large }}"
                  data-fancybox="gallery">
                  <img src="{{ imageInfo.value.large }}" alt="screenshot image" class="responsive-img" />
                </a>
              </div>
              <div class="card-content">
                <div class="row" style="cursor: pointer; margin-bottom: 0px"
                  [routerLink]="['/user-details/', imageInfo.key]">
                  <div class="col s7">
                    <user-profile [user]="
                        getUserfromUserList(imageInfo.value.user_id)
                      "></user-profile>
                  </div>
                    <div class="col s5" style="text-align: right;">
                      <span style="font-size: 16px;">{{ imageInfo.value.captured_at | timeago : true }}</span><br>
                      <b><span style="font-size: 12px;">{{ imageInfo.value.type }}</span></b>
                    </div>
                </div>
              </div>
            </div> -->
          </div>
        </ng-container>
      </div>

      <div *ngIf="total_ss == 0 && !loading" class="empty_list z-depth-2 remove-border" [ngStyle]="{
          display: !PhotoList.length ? 'block' : 'none',
          visibility: !PhotoList.length ? 'visible' : 'hidden'
        }">
        <img src="assets/images/empty_list.png" alt="empty list image" />
      </div>
    </div>
    <div class="pagination-div" *ngIf="total_ss != 0">
      <mat-paginator [length]="total_ss" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]"
        aria-label="Select page" (page)="onPageChange($event)" #EmpPaginator>
      </mat-paginator>
    </div>
  </mat-card>

  <!-- Section-2  -->
</div>

<!-- Section-2  -->

<!-- New Code :: -->

<!-- data Binded Old code :::::::: -->

<app-loader *ngIf="loading"></app-loader>
<div class="row" *ngIf="isDashboard">
  <div class="col xl6 s12 m12" *ngIf="ArrFirstlabels.length">
      <div *ngFor="let item of ArrFirstlabels;let index = index" class="row chartLabel">
        <div class="row">
        <div class="col s3"  matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
       
        <div class="col s6">
          <!-- <div style="width: 1px;"></div> -->
          <div  id="progress_bar" class="progressDiv addMargin" [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate}" placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar" [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor1[index],'height': '20px'}"  class="addMargin">
              </div>
          </div>
          <!-- <mat-progress-bar class="custom-progress-bar"
          mode="determinate"
          [style]="getProgressBarFillColor(index)"
          [value]="timeToPercentage(item.usageTime,maxTime)">
        </mat-progress-bar> -->
        
      </div>
        <div class="col s3">
         {{timeToHours(item.usageTime)}}
        </div>
        </div>
     
      </div>
  </div>
  <div class="col xl6 s12 m12" *ngIf="!ArrFirstlabels.length">
      <div class="row chartLabel">
        <div class="col xl6 s6 m6" style="text-align: end;">No used application</div>
        <span class="col xl6 s6 m6">
          <div class="progressDiv addMargin">
              <!-- <div [ngStyle]="{'width': '20%','background-color': BarColor1[index],'height': '20px','border-radius':'10px'}">
              </div> -->
          </div>
          <!-- <mat-progress-bar class="custom-progress-bar"
          mode="determinate"
          [value]="0">
        </mat-progress-bar> -->
        </span>
        <!-- <span class="col s2">
        </span> -->
      </div>
  </div>
  <div class="col xl6 s12 m12">
      <div *ngFor="let item of ArrSecondlabels;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <!-- <div style="width: 1px;"></div> -->
            <!-- <div class="progressDiv addMargin"> -->
            <div class="progressDiv addMargin" id="progress_bar" [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate}" placement="bottom" delay="200" style="cursor: pointer;">
                <div id="progress_bar" [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor2[index],'height': '20px'}" class="addMargin">
                </div>
            </div>
            <!-- <mat-progress-bar class="custom-progress-bar"
            mode="determinate"
            [style]="getProgressBarFillColor(index)"
            [value]="timeToPercentage(item.usageTime,maxTime)">
          </mat-progress-bar> -->
          
        </div>
          <div class="col s3">
           {{timeToHours(item.usageTime)}}
          </div>
          </div>
  </div>
</div>
</div>
<div class="row  chart-label-container" *ngIf="!isDashboard">
<div class="col xl12 s12 m12" *ngIf="display.length">
    <div *ngFor="let item of display;let index = index" class=" chartLabelVal">
      <div class="row chart-label-contain">
        <div class="col xl2 s2 m2 chart-label-dot-contain">
        <div  [ngStyle]="{'width': '14px','background-color': BarColor[index],'height': '14px','text-align': 'end','border-radius':'50%'}" >
        </div>
        </div>
      
        <div class="col xl6 s6 m6 app-name" matTooltip="{{item.appName}}" [ngStyle]="{'color':BarColor[index]}">
        {{item.appName}}
        
      
        </div>
         <div class="col xl4 s4 m4 hours">
       {{timeToHours(item.usageTime)}} 
         </div>
      </div>
   
    </div>
</div>
<div class="col s12" *ngIf="!display.length">
    <div class="row chartLabelVal">
      <div class="col xl2 s2 m2">
        <div  [ngStyle]="{'width': '20px','background-color':' #f1f1f4','height': '20px','text-align': 'end','border-radius':'50%'}" >
        </div>
      </div>
      <div class="col xl10 s10 m10" style="color: #8B8BA7;">No used application</div>
      <!-- <span class="col s6">
        <div class="progressDiv addMargin">
          
        </div>
      
      </span> -->
      <!-- <span class="col s2">
      </span> -->
    </div>
</div>
</div>
<div class="login_section">
	<div class="image_section">
		<img src="../../../assets/login-image.webp">
	</div>
	<div class="form_section">
		<form [formGroup]="loginForm">
			<div class="logo" (click)="openSupersee()">
				<img src="../../../assets/icons/icon-144x144.png">
				<h4>Super<strong style="font-weight: 700;">See</strong></h4>
			</div>
			<div class="welcome_section">
				<h4>Welcome Back</h4>
				<p>Start your journey with supersee with it amazing features</p>
			</div>
			<div class="input_sections">
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Enter your work email</mat-label>
						<input matInput formControlName="username" placeholder="Enter your work email">
						<!-- <mat-hint>Please Enter Email</mat-hint> -->
					</mat-form-field>
					<mat-error
						*ngIf="loginForm.get('username')?.hasError('required') && (loginForm.get('username')?.dirty || loginForm.get('username')?.touched)">
						Please enter email
					</mat-error>
				</div>
				<div class="input_box">
					<mat-form-field class="mat-form" appearance="outline">
						<mat-label>Enter Password</mat-label>
						<input matInput formControlName="password" placeholder="Enter Password"
							[type]="hide ? 'password' : 'text'" (keydown.enter)="loginOnEnter($event)">
						<button matSuffix class="passBtn">
							<mat-icon (click)="this.hide = !this.hide;">{{hide ? 'visibility_off' :
								'visibility'}}</mat-icon>
						</button>
					</mat-form-field>
					<!-- <mat-error
						*ngIf="loginForm.get('password')?.hasError('required') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Please enter password
					</mat-error> -->
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('required') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password is required.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('minlength') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must be at least 8 characters long.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('maxlength') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must not exceed 20 characters.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('space') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must not start or end with a spaces.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('uppercase') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must contain at least one uppercase letter.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('lowercase') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must contain at least one lowercase letter.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('number') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must contain at least one number.
					</mat-error>
					<mat-error
						*ngIf="loginForm.get('password')?.hasError('special') && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
						Password must contain at least one special character. <br>
						Allowed special characters: ! &quot; # $ % &amp; &apos; ( ) * + , - . / : ; &lt;
						= &gt; ? &#64; [ \ ] ^ _ {{ '{' }} {{ '}' }} | ~
					</mat-error>
				</div>
			</div>
			<div class="button_section">
				<button mat-raised-button [disabled]="!loginForm.valid || isloading == true" (click)="userLogin()"
					class="btn signInBtn" extended>Sign In</button>
			</div>
			<div class="link_section">
				<p class="forgot_password" [routerLink]="'/forgot-password'">Forgot Password?</p>
				<div class="divider-contain">
					<div class="divider"><mat-divider></mat-divider></div>
					<div class="or">
						<p>Or</p>
					</div>
					<div class="divider"><mat-divider></mat-divider></div>
				</div>
				<p>Don't have an account? <a (click)="gotoSignUp()">Sign Up</a></p>
			</div>
		</form>
	</div>
</div>

<app-loader *ngIf="loading"></app-loader>
<div class="container-fluid">
    <div class="row container">
        <div class="col s12 l5 xl6">
          <h5>FAQs</h5>
        </div>
    </div>
    <div class="row container">
        <cdk-accordion class="example-accordion">
            @for (item of items; track item; let index = $index) {
              <cdk-accordion-item
                #accordionItem="cdkAccordionItem"
                class="example-accordion-item"
                role="button"
                tabindex="0"
                [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ item.title }}
                </div>
                <div
                  class="example-accordion-item-body"
                  role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'"
                  [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index"
                  [innerHTML]="item.description">
                </div>
              </cdk-accordion-item>
            }
        </cdk-accordion>
    </div>
</div>
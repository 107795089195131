import { Auth } from '@angular/fire/auth';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Component, isDevMode } from "@angular/core";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./Admin/login/login.component";
import { UserListComponent } from "./Admin/user-list/user-list.component";
import { ScreenShotViwerComponent } from "./Admin/screen-shot-viwer/screen-shot-viwer.component";
import { HeaderComponent } from "./Admin/header/header.component";
import { CreateUserComponent } from "./Admin/create-user/create-user.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { ProjectsComponent } from "./Admin/projects/projects.component";
import { AddProjectComponent } from "./Admin/projects/add-project/add-project.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from '@angular/material/sidenav';
import { TooltipDirectiveDirective } from "./tooltip-directive.directive";

import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_DATA,
  MatDialogActions,
} from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";

import { SharedService } from "./Services/sharedService";

import { TimeLogsComponent } from "./Admin/time-logs/time-logs.component";
import { TabsComponent } from "./Admin/tabs/tabs.component";
import { LogsDisplayModalComponent } from "./Admin/logs-display-modal/logs-display-modal.component";
import { LoaderComponent } from "./Admin/loader/loader.component";
import { DashboardComponent } from "./Admin/dashboard/dashboard.component";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { TimeagoModule } from "ngx-timeago";
import { config } from "./config";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";

import { StatusFilterPipe } from "./status-filter.pipe";
import { ChartComponent } from "./chart/chart.component";
import { PieChartComponent } from "./pie-chart/pie-chart.component";
// import { SelectAutocompleteModule } from "mat-select-autocomplete";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { environment } from "../environments/environment";
import { MatSelectSearchComponent } from "./Admin/mat-select-search/mat-select-search.component";
import { ScreencastActionModelComponent } from "./screencast/screencast-action-model/screencast-action-model.component";
import { Overlay, OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { HeaderInterceptor } from "./header-interceptor.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DisplayToastComponent } from "./DisplayToast/display-toast/display-toast.component";
import { TeamListComponent } from "./Admin/projects/team-list/team-list.component";
import { TeamMemberListComponent } from "./Admin/projects/team-member-list/team-member-list.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { UsersTableComponent } from "./Admin/users-table/users-table.component";
import { StackedChartComponent } from "./stacked-chart/stacked-chart.component";
import { LineChartComponent } from "./line-chart/line-chart.component";
import { DonutChartComponent } from "./donut-chart/donut-chart.component";
import { CustomTooltipDirective } from "./custom-tooltip.directive";
import { TooltipModule } from "ng2-tooltip-directive";
import { UserProfileComponent } from "./Admin/user-profile/user-profile.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { FormGuard, UserSettingPanelComponent } from './Admin/user-setting-panel/user-setting-panel.component';
import { EmployeeEditComponent } from './Admin/employee-edit/employee-edit.component';
import { ApplicationUsageChartComponent } from './ApplicationUsageChart/application-usage-chart/application-usage-chart.component';
import { ApplicationUSageProgressbarComponent } from './ApplicationUSageProgressbar/application-usage-progressbar/application-usage-progressbar.component';
// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu'
import { MatDividerModule } from '@angular/material/divider';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { VerificationCodeComponent } from './verification-code/verification-code.component';
import { ResetSucessfullyComponent } from './reset-sucessfully/reset-sucessfully.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AvatarModule } from 'ngx-avatars';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuotaBannerComponent } from "./QuotaBanner/quota-banner/quota-banner.component";
import { MessagingServiceService } from "./Services/messaging-service.service";
import { DialogTemplateComponent } from "./dialog-template/dialog-template/dialog-template.component";
import { UserProfileInfoComponent } from "./user-profile-info/user-profile-info/user-profile-info.component";
import { AddEmailComponent } from "./addEmail/add-email/add-email.component";
import { UserPlansComponent } from './user-plans/user-plans/user-plans.component';
import { PlanExpiredComponent } from './QuotaBanner/plan-expired/plan-expired.component';
import { PaymentHistoryComponent } from './user-plans/payment-history/payment-history.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ApplyCouponComponent } from './user-plans/apply-coupon/apply-coupon.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { UserReportComponent } from './Admin/user-list/user-report/user-report.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PolicyFooterComponent } from './policy-footer/policy-footer.component';
import { NgxCountriesDropdownModule } from 'ngx-countries-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserListComponent,
    ScreenShotViwerComponent,
    HeaderComponent,
    CreateUserComponent,
    ProjectsComponent,
    AddProjectComponent,
    TimeLogsComponent,
    TabsComponent,
    LogsDisplayModalComponent,
    LoaderComponent,
    DashboardComponent,
    StatusFilterPipe,
    ChartComponent,
    PieChartComponent,
    MatSelectSearchComponent,
    ScreencastActionModelComponent,
    DisplayToastComponent,
    TeamListComponent,
    TeamMemberListComponent,
    ConfirmationDialogComponent,
    UsersTableComponent,
    StackedChartComponent,
    LineChartComponent,
    DonutChartComponent,
    CustomTooltipDirective,
    UserProfileComponent,
    UserSettingPanelComponent,
    EmployeeEditComponent,
    ApplicationUsageChartComponent,
    ApplicationUSageProgressbarComponent,
    SignupComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    VerificationCodeComponent,
    ResetSucessfullyComponent,
    WelcomeComponent,
    QuotaBannerComponent,
    DialogTemplateComponent,
    UserProfileInfoComponent,
    AddEmailComponent,
    TooltipDirectiveDirective,
    UserPlansComponent,
    PlanExpiredComponent,
    PaymentHistoryComponent,
    ApplyCouponComponent,
    UserReportComponent,
  ],
  imports: [

    AngularFireMessagingModule,
    MatSnackBarModule,
    MatProgressBarModule,
    OverlayModule,
    PortalModule,
    HttpClientModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TourMatMenuModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSelectModule,
    AvatarModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    BrowserModule,
    TooltipModule,
    MatSidenavModule,
    TimeagoModule.forRoot(),
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    MatCheckboxModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    PolicyFooterComponent,
    NgxCountriesDropdownModule
  ],
  providers: [

    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    SharedService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ProjectService } from './Services/project.service';

@Directive({
  selector: '[tooltipDirective]',
})
export class TooltipDirectiveDirective {
  @Input('tooltip') tooltipTitle: string;
  @Input() placement: string;
  @Input() delay: any;
  @Input('tooltipDirective') tooltipDirective: any;
  tooltipData = []
  userList = [];
  tooltip: HTMLElement;
  currentTooltip:HTMLElement;
  offset = 10;
  constructor(private el: ElementRef, private renderer: Renderer2,private _projectService:ProjectService) {
    // this.getUserList()
   }

  @HostListener('click') onClick() {
    if (!this.tooltip) {
      this.show();
    } else {
      this.hide();
    }
    if(this.tooltip){
      this
      ._projectService
      .getUserList(0, 0).subscribe((user: any) => {
        this.userList = user;
      this._projectService.getAppUsageTooltipData(this.tooltipDirective.appName, this.tooltipDirective.star_date, this.tooltipDirective.end_date).subscribe((res) => {
        let userData:any=res;
        this.tooltipData = [];
        for(let i = 0; i <= userData.length-1;i++){
        let obj =   {
          user : this.displayUserName(userData[i].user_id),
          applicationUsage: this.convertIntoTime(userData[i].usageTime)
          }
          this.tooltipData.push(obj)
        }

        this.tooltipData.sort((a, b) => {
          const timeA = parseInt(a.applicationUsage.split(':')[0]) * 60 + parseInt(a.applicationUsage.split(':')[1]);
          const timeB = parseInt(b.applicationUsage.split(':')[0]) * 60 + parseInt(b.applicationUsage.split(':')[1]);
          return timeB - timeA;
      });
        this.addApplicationDataDiv();
      })
    })
    }
  }
  convertIntoTime(selectedData){
    const [hours, minutes, seconds] = selectedData.split(":").map(Number);
    // return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}`;

    return `${this.padZeroes(hours)}:${this.padZeroes(minutes)}` + "h";
  }
  padZeroes(value: number) {
    return value.toString().padStart(2, '0');
  }
  displayUserName(id) {
    let resData = this.userList.filter((data: any) => {
      let userNameArr = data.name.split(" ");
      for(let i = 0; i < userNameArr.length; i++){
        userNameArr[i] = userNameArr[i].charAt(0).toUpperCase() + userNameArr[i].slice(1);
      }
      data.name = userNameArr.join(" ");
      return data.id == id;
    });
    return resData.length ? resData[0].name : "";
  }
  getUserList(){
    this
    ._projectService
    .getUserList(0, 0).subscribe((user: any) => {
      this.userList = user;
    })
  }
  show() {
    if (document.getElementById('TooltipId')) {
      var existingTooltip = document.getElementById('TooltipId');
      existingTooltip.parentNode.removeChild(existingTooltip);
  } 
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('div');
    this.currentTooltip = this.tooltip;
    this.tooltip.id = 'TooltipId'; // Set your desired ID here

    // <div class="tooltip">
    // 	<div class="tooltip-title">
    // 	  <h3>Google Chrome</h3>
    // 	</div>
    // 
    // 	 <div class="tooltip-header">
    // 	    <div>
    //          <h4>Users</h4>
    //          <h4>Application Usage</h4>
    // 	 	  </div>
    // 	 </div>
    //    <div class="tooltip-body">
    //      <div>
    //          <h5>Dhrumil Gandhi</h5>
    //          <h5>10:00:00</h5>
    //      </div>
    //      <div>
    //          <h5>Keval Vala</h5>
    //          <h5>09:00:00</h5>
    //      </div>
    //      <div>
    //          <h5>Ashish Paramar</h5>
    //          <h5>06:00:00</h5>
    //      </div>
    //    </div>
    //  </div>
    // </div>

    // this is for header part creating the headers for tooltip.
    let tooltipTitle = this.renderer.createElement('div');
    this.renderer.addClass(tooltipTitle, 'tooltip-title');
    let h3 = this.renderer.createElement('h6');
    this.renderer.appendChild(
      h3,
      this.renderer.createText(this.tooltipDirective.appName)
    );
    this.renderer.appendChild(
      tooltipTitle,
      h3
    );
    this.renderer.appendChild(
      this.tooltip,
      tooltipTitle
    );
    let tooltipHeader = this.renderer.createElement('div');
    this.renderer.addClass(tooltipHeader, 'tooltip-header');

    let innerDivOfTooltipHeader = this.renderer.createElement('div');
    let userTitle = this.renderer.createElement('h6');
    this.renderer.appendChild(
      userTitle,
      this.renderer.createText("Users")
    );
    let applicationUsageTitle = this.renderer.createElement('h6');
    this.renderer.appendChild(
      applicationUsageTitle,
      this.renderer.createText("Application Usage")
    );
    this.renderer.appendChild(innerDivOfTooltipHeader, userTitle);
    this.renderer.appendChild(innerDivOfTooltipHeader, applicationUsageTitle);
    this.renderer.appendChild(tooltipHeader, innerDivOfTooltipHeader);
    this.renderer.appendChild(this.tooltip, tooltipHeader);


    // this is for header part creating the headers for tooltip.
    let tooltipBody = this.renderer.createElement('div');
    tooltipBody.id = 'TooltipBodyId'; // Set your desired ID here

    
    this.renderer.addClass(tooltipBody , "tooltip-body");
    let innerDivOfTooltipBodyLoading = this.renderer.createElement('div');
    tooltipBody.id = 'TooltipBodyLoadingId'; // Set your desired ID here
    let LoadingTag = this.renderer.createElement('span');
       this.renderer.appendChild(
        LoadingTag,
        this.renderer.createText("Loading...")
      );
      this.renderer.appendChild(innerDivOfTooltipBodyLoading, LoadingTag);
     this.renderer.appendChild(tooltipBody, innerDivOfTooltipBodyLoading)

    // this.tooltipData.forEach((users: any) => {
    //   let innerDivOfTooltipBody = this.renderer.createElement('div');
    //   let applicationUser = this.renderer.createElement('p');
    //   let applicationUsage = this.renderer.createElement('p');
    //   this.renderer.appendChild(
    //     applicationUser,
    //     this.renderer.createText(users.user)
    //   );
    //   this.renderer.appendChild(
    //     applicationUsage,
    //     this.renderer.createText(users.applicationUsage)
    //   );
    //   this.renderer.appendChild(innerDivOfTooltipBody, applicationUser);
    //   this.renderer.appendChild(innerDivOfTooltipBody, applicationUsage);
    //   this.renderer.appendChild(tooltipBody, innerDivOfTooltipBody)
    // });

    this.renderer.appendChild(this.tooltip, tooltipBody);



    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
    this.renderer.setStyle(
      this.tooltip,
      '-webkit-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-moz-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-o-transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      'transition',
      `opacity ${this.delay}ms`
    );
    this.renderer.appendChild(document.body, this.tooltip);
  
  }

  addApplicationDataDiv(){
    if (document.getElementById('TooltipBodyLoadingId')) {
      var existingTooltip = document.getElementById('TooltipBodyLoadingId');
      existingTooltip.parentNode.removeChild(existingTooltip);
      let tooltipBody = this.renderer.createElement('div');
      tooltipBody.id = 'TooltipBodyId'; // Set your desired ID here  
      this.renderer.addClass(tooltipBody , "tooltip-body");
       this.tooltipData.forEach((users: any) => {
      let innerDivOfTooltipBody = this.renderer.createElement('div');
      let applicationUser = this.renderer.createElement('p');
      let applicationUsage = this.renderer.createElement('p');
      this.renderer.appendChild(
        applicationUser,
        this.renderer.createText(users.user)
      );
      this.renderer.appendChild(
        applicationUsage,
        this.renderer.createText(users.applicationUsage)
      );
      this.renderer.appendChild(innerDivOfTooltipBody, applicationUser);
      this.renderer.appendChild(innerDivOfTooltipBody, applicationUsage);
      this.renderer.appendChild(tooltipBody, innerDivOfTooltipBody)
    });
    this.renderer.appendChild(this.tooltip, tooltipBody);
  } 
  }
  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }


    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
